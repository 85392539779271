/* eslint-disable space-before-function-paren */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '@material-ui/core'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import callService from 'pages/settings/panes/HelpAndSupport/callService'
import Modal from 'components/containers/Modal'
import CircularLoader from 'components/CircularLoader'

import createStore from 'createStore'

import { Button } from 'components/buttons'

import dayjs from 'dayjs'
import i18n from 'i18n'
import { Formik, Form, ErrorMessage } from 'formik'
import * as yup from 'yup'

import { Calendar } from 'react-multi-date-picker'

import { useResource, useResourceAction } from 'hooks/resources'
import { jobSchema } from '@seekster/schemas'
import { ChevronLeft, ChevronRight } from '@material-ui/icons'

import SectionRight from '../reschedule/modules/SectionRight'

import './reschedule.scss'

export const store = createStore()

export function JobReschedulePage({ match }) {
  const history = useHistory()
  const locale = i18n.language
  const { t } = useTranslation(['actions', 'jobs', 'attributes'])
  const [job, { loading }] = useResource(jobSchema, match.params.id)
  const { triggerWithData: triggerJob } = useResourceAction(jobSchema)
  const accessToken = store.getState().getIn(['authentication', 'token'])
  const header = { authorization: `Bearer ${accessToken}` }

  const id = job.toJS()?.service?.id
  const isSlot = job.toJS()?.service?.slot_enabled || false
  const [rawData, setRawData] = useState({})
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const [openModalConfirm, setOpenModalConfirm] = useState(false)
  const [successLoading, setSuccessLoading] = useState(false)
  const [timeSelect, setTimeSelect] = useState(false)

  const [time, setTime] = useState({
    normal: [
      {
        id: 1,
        time: '2022-08-30T09:00:00.000+07:00',
        active: false,
        disableBtn: true
      },
      {
        id: 2,
        time: '2022-08-30T10:00:00.000+07:00',
        active: false,
        disableBtn: true
      },
      {
        id: 3,
        time: '2022-08-30T11:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 4,
        time: '2022-08-30T12:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 5,
        time: '2022-08-30T13:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 6,
        time: '2022-08-30T14:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 7,
        time: '2022-08-30T15:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 8,
        time: '2022-08-30T16:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 9,
        time: '2022-08-30T17:00:00.000+07:00',
        active: false,
        disableBtn: false
      },
      {
        id: 10,
        time: '2022-08-30T18:00:00.000+07:00',
        active: false,
        disableBtn: false
      }
    ],
    slot: [
      {
        id: 1,
        start_time: '2022-08-30T09:00:00.000+07:00',
        end_time: '2022-08-30T10:00:00.000+07:00',
        active: false,
        disableBtn: true,
        remain: 50
      },
      {
        id: 2,
        start_time: '2022-08-30T12:00:00.000+07:00',
        end_time: '2022-08-30T15:00:00.000+07:00',
        active: false,
        disableBtn: true,
        remain: 60
      },
      {
        id: 3,
        start_time: '2022-08-30T16:00:00.000+07:00',
        end_time: '2022-08-30T18:00:00.000+07:00',
        active: false,
        disableBtn: false,
        remain: 100
      },
      {
        id: 4,
        start_time: '2022-08-30T18:00:00.000+07:00',
        end_time: '2022-08-30T19:00:00.000+07:00',
        active: false,
        disableBtn: false,
        remain: 100
      }
    ]
  })

  // const slotEnabled = job.getIn(['service', 'slot_enabled'])

  // function handleReschedule(data) {
  //   return triggerJob(match.params.id, 'reschedule', data)
  // }

  // function handleRedistribute(data) {
  //   return triggerJob(match.params.id, 'redistribute', data)
  // }

  // function breadcrumbs() {
  //   return [
  //     { text: 'Jobs', link: '/jobs' },
  //     { text: job.get('number'), link: `/jobs/${match.params.id}` },
  //     { text: t('reschedule') }
  //   ]
  // }

  useEffect(() => {
    if (job.toJS()?.start_time) {
      setTime((prevTime) => {
        const updatedSlot = prevTime.slot.map((slot) => {
          if (
            dayjs(slot.time).format('HH') === dayjs(job.toJS()?.start_time).format('HH')
          ) {
            return {
              ...slot,
              active: true
            }
          } else {
            return {
              ...slot,
              active: false
            }
          }
        })
        const updatedNormal = prevTime.normal.map((normal) => {
          if (
            dayjs(normal.time).format('HH') === dayjs(job.toJS()?.start_time).format('HH')
          ) {
            return {
              ...normal,
              active: true
            }
          } else {
            return {
              ...normal,
              active: false
            }
          }
        })
        if (isSlot) {
          return {
            ...prevTime,
            slot: updatedSlot
          }
        } else {
          return {
            ...prevTime,
            normal: updatedNormal
          }
        }
      })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [job])

  const initialValues = {
    textarea: '',
    day_time_select: dayjs(job.toJS()?.start_time).format() || '',
    start_time_select: dayjs(job.toJS()?.start_time).format() || '',
    end_time_select: dayjs(job.toJS()?.start_time).format() || '',
    checked: [],
    hours: '',
    min: '',
    hhmm: ''
  }

  const ValidationSchema = yup.object().shape({
    hours: yup.string().matches(/^[0-9]*$/, 'Number only.'),
    min: yup.string().matches(/^[0-9]*$/, 'Number only.'),
    hhmm: yup
      .string()
      .matches(
        /^([01]?[0-9]|2[0-3]):[0-5][0-9]$/,
        'Please enter a valid hour and minutes'
      )
  })

  // eslint-disable-next-line space-before-function-paren
  const callList = async (serviceId) => {
    const response = await callService(
      'GET',
      `${process.env.REACT_APP_RESCHEDULE_API}/services/${serviceId}/reschedule`,
      null,
      header
    )

    if (response.status === 200 || response.status === 201) {
      setRawData(response.data)
    }
  }

  const handleChange = (action, e, name) => {
    const { checked } = e.target
    if (checked) {
      action.setFieldValue('checked', [...action.values.checked, name])
    } else {
      action.setFieldValue(
        'checked',
        action.values.checked.filter((v) => v !== name)
      )
    }
  }

  const changeValueTextarea = (e, action) => {
    action.setFieldValue('textarea', e.target.value)
  }

  const subMitValue = async (action) => {
    const fullMonth = dayjs(action.day_time_select).format('YYYY-MM-DD')
    const timeDate = dayjs(action.start_time_select).format('HH:00')
    const startFormat = `${fullMonth}T${timeDate}:00+07:00`
    const selectTimeFormat = `${fullMonth}T${action.hhmm}:00+07:00`
    const slotStartTime = `${fullMonth}T${dayjs(action.start_time_select).format(
      'HH:mm'
    )}:00+07:00`
    const slotEndTime = `${fullMonth}T${dayjs(action.end_time_select).format(
      'HH:mm'
    )}:00+07:00`

    const data = {
      reschedule_start_time: isSlot
        ? slotStartTime
        : timeSelect
        ? selectTimeFormat
        : startFormat,
      reschedule_end_time: isSlot ? slotEndTime : null,
      reschedule_reason_ids: action.checked,
      reschedule_other_reason: action.textarea,
      vat: 0,
      fee: 0,
      fee_no_vat: 0
    }

    const response = await callService(
      'POST',
      `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-job/jobs/${match.params.id}`,
      data,
      header
    )

    if (response?.status === 200 || response?.status === 201) {
      console.log('Yes')
    } else {
      console.log(response?.data.data.message)
    }

    setOpenModalConfirm(false)
    setOpenModalSuccess(true)
    setTimeout(() => {
      setOpenModalSuccess(false)
      setSuccessLoading(false)
      history.push(`/jobs/${match.params.id}`)
    }, 3000)
  }

  const changeValueCalenda = async (date) => {
    if (isSlot) {
      const selectDate = dayjs(date.format()).format('YYYY-MM-DD')
      const url = `${
        process.env.REACT_APP_BASE_API
      }/services/${id}/slots?date=${selectDate}&page=${1}&locale=${locale}`

      const response = await callService('GET', `${url}`, null, header)
      if (response.status === 200 || response.status === 201) {
        setTime((prev) => {
          return {
            ...prev,
            slot: response.data
          }
        })
      }
    }
  }

  const checkErrors = (action) => {
    const check = Object.keys(action.errors).length === 0
    if (!check) {
      setOpenModalConfirm(false)
    }
    action.submitForm()
  }

  useEffect(() => {
    if (id) {
      if (isSlot) {
        changeValueCalenda(dayjs(job.toJS()?.start_time))
      }
      callList(id)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id])

  return (
    <>
      <div className='section-bg-reschedule'>
        <div className='section-id-tax-and-status'>
          <div className='id-text-show'>
            <div>{t('reschedule')}</div>
          </div>
        </div>
        <div className='section-back-to-previous'>
          <ChevronLeft
            className='icon-left pointer'
            onClick={() => history.push(`/jobs/${match.params.id}`)}
          />
          <div
            className='pointer'
            onClick={() => history.push(`/jobs/${match.params.id}`)}
          >
            Back to previous
          </div>
        </div>
      </div>
      <div className='section-show-time-and-number'>
        <div className='card-topic-job'>
          <div className='d-flex-full-job'>
            <div className='font-number-job pr-5'>{job.toJS().number}</div>
            <div className='d-flex-full-job'>
              <div className='date-number-job pr-5'>
                <span className='pr-1'>Date</span>
                <span className='inside-date'>
                  {dayjs(job.toJS().start_time).format('DD-MM-YYYY')}
                </span>
              </div>
              <div className='start-time-number-job'>
                <span className='pr-1'>Start time</span>
                <span className='inside-start-time'>
                  {dayjs(job.toJS().start_time).format('HH:mm')}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='card-topic-job'>
        <div>
          <span className='pr-1 head-topic-job '>Select reschedule new date</span>
          <span className='dot-alert'>*</span>
        </div>
        <div className='title-topic-job'>
          To continue with the rescheduling job, adate and time must be selected
        </div>
      </div>

      <div>
        <Formik
          enableReinitialize
          initialValues={initialValues}
          validationSchema={ValidationSchema}
          onSubmit={subMitValue}
        >
          {(formik) => (
            <Form>
              <div className='bg-calendar-job'>
                <div className='card-topic-job'>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={6}>
                      <div className='section-carenda-custom'>
                        <Calendar
                          value={dayjs(formik.values.day_time_select).format(
                            'YYYY/MM/DD'
                          )}
                          onChange={(date) => {
                            changeValueCalenda(date, formik)
                            formik.setFieldValue(
                              'day_time_select',
                              dayjs(date.toDate()).format()
                            )
                          }}
                          showOtherDays
                          disableMonthPicker
                          disableYearPicker
                          minDate={dayjs().toDate()}
                          maxDate={dayjs().add(15, 'day').format()}
                          mapDays={({ date, today, isSameDate }) => {
                            if (isSameDate(date, today)) {
                              return {
                                children: (
                                  <div className='custom-today'>
                                    <div
                                      style={{
                                        width: '7px',
                                        height: '7px',
                                        backgroundColor: 'red',
                                        borderRadius: '50%',
                                        position: 'absolute'
                                      }}
                                    />
                                    <div className='text-today'>{today.day}</div>
                                  </div>
                                )
                              }
                            }
                            return {
                              onMouseDown: (e) => {
                                e.stopPropagation()
                                e.preventDefault()
                              }
                            }
                          }}
                          renderButton={(direction, handleClick) => (
                            <div
                              onMouseDown={(e) => {
                                e.preventDefault()
                                handleClick()
                              }}
                              className='pointer arrow-next-carlendar-refund'
                            >
                              {direction === 'right' ? <ChevronRight /> : <ChevronLeft />}
                            </div>
                          )}
                        >
                          <div className='today-and-dot'>
                            <div className='dot' />
                            <div className='today-text'>Today</div>
                          </div>
                        </Calendar>
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <SectionRight
                        isSlot={isSlot}
                        action={formik}
                        timeSelect={timeSelect}
                        setTimeSelect={setTimeSelect}
                        setTime={setTime}
                        time={time}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>

              {rawData?.is_reason_choice_enabled && (
                <div className='border-reschedule-bottom'>
                  <div className='card-topic-job'>
                    <div className='topic-checkbox-job'>
                      <span className='pr-1'>
                        {locale === 'en'
                          ? rawData?.title_reason_choice_en
                          : rawData?.title_reason_choice_th}
                      </span>
                      <span className='tool-tip-topic'>(Optional)</span>
                    </div>
                    <div className='mt-2'>
                      {rawData?.reschedule_reason_choices.map((data, k) => {
                        return (
                          <div key={k} className='field-checkbox-custom'>
                            <div className='pr-3'>
                              <input
                                id={data.reschedule_reasons.id}
                                type='checkbox'
                                name={`checked${[k]}`}
                                onChange={(e) =>
                                  handleChange(formik, e, data.reschedule_reasons.id)
                                }
                              />
                            </div>
                            <div className='box-content-custom'>
                              <p className='data-question-custom-map'>
                                {locale === 'en'
                                  ? data.reschedule_reasons.name_en
                                  : data.reschedule_reasons.nameth}
                              </p>
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              )}

              {rawData?.is_reason_other_enabled && (
                <div className='border-reschedule-bottom'>
                  <div className='card-topic-job'>
                    <div className='topic-checkbox-job'>
                      <span className='pr-1'>
                        {locale === 'en'
                          ? rawData?.title_reason_other_en
                          : rawData?.title_reason_other_th}
                      </span>
                      <span className='tool-tip-topic'>(Optional)</span>
                    </div>
                    <div>
                      <Grid container spacing={3}>
                        <Grid item xs={12} md={10}>
                          <textarea
                            name='textarea'
                            className='mt-2 text-area-reschedule'
                            autoComplete='off'
                            placeholder='Input the reasons here'
                            onChange={(e) => changeValueTextarea(e, formik)}
                          />
                          <div className='text-validate mt-1'>
                            <ErrorMessage
                              name='textarea'
                              component='div'
                              className='field-error'
                            />
                          </div>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </div>
              )}
              <div className='section-button-submit-reschedule-job'>
                <div className='card-topic-job'>
                  <Button
                    className='btn-reschedule-job-custom'
                    onClick={() => setOpenModalConfirm(true)}
                  >
                    {t('reschedule')}
                  </Button>
                </div>
              </div>
              <Modal
                isOpen={openModalConfirm}
                onCloseModal={() => setOpenModalConfirm(false)}
                className='section-custom-main-modal'
              >
                {successLoading ? (
                  <CircularLoader />
                ) : (
                  <div className='custom-padding-modal'>
                    <div className='text-center'>
                      <div className='modal-topic'>{t('modal.topic')}</div>
                      <div className='modal-topic'>
                        "{job.toJS().number} from{' '}
                        {dayjs(job.toJS().start_time).format('DD-MM-YYYY')} time{' '}
                        {dayjs(job.toJS().start_time).format('HH:mm')}"
                      </div>
                      <div className='modal-content mt-3'>{t('modal.title')}</div>
                    </div>
                    <div className='d-flex align-item-center justify-content-center mt-2'>
                      <button
                        className='custom-btn-cancel-modal-2 d-flex align-item-center justify-content-center pointer'
                        onClick={() => setOpenModalConfirm((pre) => !pre)}
                      >
                        {t('btn.cancel')}
                      </button>
                      <button
                        className='custom-btn-cancel-modal-1 d-flex align-item-center justify-content-center pointer'
                        type='submit'
                        onClick={() => checkErrors(formik)}
                      >
                        {t('btn.confrim')}
                      </button>
                    </div>
                  </div>
                )}
              </Modal>
              <Modal
                isOpen={openModalSuccess}
                onCloseModal={() => setOpenModalSuccess(false)}
                className='section-custom-main-modal-success'
              >
                <div className='d-flex align-item-center justify-content-between'>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='32.94'
                      height='32.94'
                      viewBox='0 0 32.94 32.94'
                    >
                      <path
                        d='M33.5,17.032A16.47,16.47,0,1,1,17.032.562,16.47,16.47,0,0,1,33.5,17.032ZM15.127,25.753l12.22-12.22a1.063,1.063,0,0,0,0-1.5l-1.5-1.5a1.063,1.063,0,0,0-1.5,0l-9.966,9.965L9.723,15.841a1.063,1.063,0,0,0-1.5,0l-1.5,1.5a1.063,1.063,0,0,0,0,1.5l6.907,6.907a1.063,1.063,0,0,0,1.5,0Z'
                        transform='translate(-0.562 -0.562)'
                        fill='#1b4171'
                      />
                    </svg>
                  </div>
                  <div className='topic-success-modal'>{t('reschedule_success')}</div>
                </div>
              </Modal>
            </Form>
          )}
        </Formik>
      </div>

      {/* <Grid container justify='center'>
        <Grid item xs={12} sm={9}>
          <Panel
            title={t('start_time')}
            actions={slotEnabled ? null : <TimePickerToggleButton />}
          >
            <JobRescheduleForm
              job={job}
              onReschedule={handleReschedule}
              onRedistribute={handleRedistribute}
            />
          </Panel>
        </Grid>
      </Grid> */}
    </>
  )
}

JobReschedulePage.propTypes = {
  match: PropTypes.object
}

export default JobReschedulePage
