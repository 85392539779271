import React from 'react'
import PropTypes from 'prop-types'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { AppIndexContent } from 'pages/apps/index'
import { ClientIndexContent } from 'pages/clients/index'
import WebhookIndexContent from 'pages/webhooks/index'

import {
  AccountingSettingsPane,
  AgentsPane,
  GeneralSettingsPane,
  MarketingSettingsPane,
  ProviderSettingsPane,
  TagManagerSettingsPane,
  CancellationSettingsPane,
  HelpAndSupport,
  SMSRatingSettingsPane,
  ServiceSettingPane
} from './panes'

import {
  IntegrationIndexPage,
  IntegrationShowPage,
  SeektownIntegrationShowPage,
  ScbQrCodeIntegrationShowPage,
  CocoroIntegrationShowPage
} from './panes/IntegrationsPane'
import SLASettingPane from './panes/SLASettingPane'
import InvoiceExpirationPane from './panes/InvoiceExpirationPane'

export function SettingsPage({ location, t }) {
  function renderSubNavbar() {
    return (
      <NavigationMenuBar path={location.pathname.split('/', 3).join('/')}>
        <NavigationMenuItem label='General Settings' to='/settings' />
        <NavigationMenuItem label='SLA' to='/settings/sla' />
        <NavigationMenuItem label='Apps' to='/settings/apps' />
        <NavigationMenuItem label='Clients' to='/settings/clients' />
        <NavigationMenuItem label='Agents' to='/settings/agents' />
        <NavigationMenuItem label='Cancellation' to='/settings/cancellations' />
        <NavigationMenuItem label='Invoice Expiration' to='/settings/invoice_expiration_settings' />
        <NavigationMenuItem label='Service Settings' to='/settings/service_settings' />
        <NavigationMenuItem label='SMS' to='/settings/sms_rating_settings' />
        <NavigationMenuItem
          label='Accounting Settings'
          to='/settings/accounting_settings'
        />
        <NavigationMenuItem label='Provider Settings' to='/settings/provider_settings' />
        <NavigationMenuItem
          label='Marketing Settings'
          to='/settings/marketing_settings'
        />
        <NavigationMenuItem label='Webhook Settings' to='/settings/webhook_settings' />
        <NavigationMenuItem
          label='Tag Manager Settings'
          to='/settings/tag_manager_settings'
        />
        <NavigationMenuItem label='Integrations' to='/settings/integrations' />
        <NavigationMenuItem label='Help&Support' to='/settings/help_support' />
      </NavigationMenuBar>
    )
  }

  return (
    <PageLayout title={t('title')} subNavbar={renderSubNavbar()}>
      <Switch>
        <AuthenticatedRoute path='/settings/service_settings' component={ServiceSettingPane} exact />
        <AuthenticatedRoute path='/settings' component={GeneralSettingsPane} exact />
        <AuthenticatedRoute path='/settings/sla' component={SLASettingPane} />
        <AuthenticatedRoute path='/settings/apps' component={AppIndexContent} />
        <AuthenticatedRoute path='/settings/clients' component={ClientIndexContent} />
        <AuthenticatedRoute path='/settings/agents' component={AgentsPane} />
        <AuthenticatedRoute
          path='/settings/cancellations'
          component={CancellationSettingsPane}
        />
        <AuthenticatedRoute
          path='/settings/invoice_expiration_settings'
          component={InvoiceExpirationPane}
        />
        <AuthenticatedRoute
          path='/settings/sms_rating_settings'
          component={SMSRatingSettingsPane}
        />
        <AuthenticatedRoute
          path='/settings/accounting_settings'
          component={AccountingSettingsPane}
        />
        <AuthenticatedRoute
          path='/settings/provider_settings'
          component={ProviderSettingsPane}
        />
        <AuthenticatedRoute
          path='/settings/marketing_settings'
          component={MarketingSettingsPane}
        />
        <NavigationMenuItem
          path='/settings/webhook_settings'
          component={WebhookIndexContent}
          exact
        />
        <AuthenticatedRoute
          path='/settings/tag_manager_settings'
          component={TagManagerSettingsPane}
        />
        <AuthenticatedRoute
          path='/settings/integrations'
          component={IntegrationIndexPage}
          exact
        />
        <AuthenticatedRoute
          path='/settings/integrations/seektown'
          component={SeektownIntegrationShowPage}
          exact
        />
        <AuthenticatedRoute
          path='/settings/integrations/cocoro'
          component={CocoroIntegrationShowPage}
          exact
        />
        <AuthenticatedRoute
          path='/settings/integrations/scb_qr_code'
          component={ScbQrCodeIntegrationShowPage}
          exact
        />
        <AuthenticatedRoute
          path='/settings/integrations/:id'
          component={IntegrationShowPage}
          exact
        />
        <AuthenticatedRoute
          path='/settings/help_support/'
          component={HelpAndSupport}
          exact
        />
      </Switch>
    </PageLayout>
  )
}

SettingsPage.propTypes = {
  location: PropTypes.object,

  t: PropTypes.func
}

export default withTranslation('settings')(SettingsPage)
