import React from 'react'
import ImmutablePropTypes from 'react-immutable-proptypes'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles'
import BooleanChip from 'components/BooleanChip'
import { Table, Column } from 'components/tables/Table'
import { Paper, Avatar } from '@material-ui/core'
import Tag from 'components/Tag'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'

const useStyles = makeStyles(({ spacing }) => ({
  icon: {
    display: 'inline-block',
    width: 20,
    height: 20,
    margin: '0 5px -5px 0'
  }
}))

export function ServicesTable({ services, loading }) {
  const classes = useStyles()

  return (
    <Paper>
      <Table collection={services} isLoading={loading}>
        <Column
          name='name'
          render={(name, service) => (
            <Link to={`/services/${service.get('id')}`}>
              <Avatar src={service.get('icon_url')} alt={name} className={classes.icon} />
              {name}
            </Link>
          )}
        />
        <Column name='code' component={Tag} />
        <Column
          name='categories'
          render={(categories) => (
            <ul>
              {categories &&
                categories.map((category, index) => {
                  return (
                    <li key={index}>
                      <Link to={`/categories/${category.get('id')}`}>
                        {category.get('name')}
                      </Link>
                    </li>
                  )
                })}
            </ul>
          )}
        />
        <Column name='requestable' component={BooleanChip} />
        <Column name='registerable' component={BooleanChip} />
        <Column name='public' component={BooleanChip} />
        <Column name='created_at' component={DateTimeFormatter} />
      </Table>
    </Paper>
  )
}

ServicesTable.propTypes = {
  services: ImmutablePropTypes.list,
  loading: PropTypes.bool
}

ServicesTable.defaultProps = {
  services: List()
}

export default ServicesTable
