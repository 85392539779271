import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { compose } from 'redux'
import { Map } from 'immutable'
import { Select, InputBase, MenuItem } from '@material-ui/core'

import { withTranslation } from 'react-i18next'

import withLocalizedFields from 'hoc/withLocalizedFields'
import PageLayout from 'components/layouts/PageLayout'
import CategoryForm from 'forms/resources/CategoryForm'

export class CategoryEditPage extends React.Component {
  static propTypes = {
    category: ImmutablePropTypes.map,
    fetchCategory: PropTypes.func,
    updateCategory: PropTypes.func,
    push: PropTypes.func,
    match: PropTypes.object,
    locale: PropTypes.string,
    setLocale: PropTypes.func,

    t: PropTypes.func
  }

  static defaultProps = {
    category: Map()
  }

  constructor(props) {
    super(props)

    this.handleSubmit = this.handleSubmit.bind(this)
  }

  componentDidMount() {
    this.props.fetchCategory(this.props.match.params.id)
  }

  handleSubmit(data, options) {
    return this.props.updateCategory(this.props.match.params.id, data, options)
  }

  actions() {
    return [
      <Select
        key='1'
        value={this.props.locale}
        onChange={(e) => this.props.setLocale(e.target.value)}
        input={<InputBase />}
      >
        <MenuItem value='en'>
          <span role='img' aria-label='GB flag'>
            🇬🇧{' '}
          </span>
          English
        </MenuItem>
        <MenuItem value='th'>
          <span role='img' aria-label='Thai flag'>
            🇹🇭{' '}
          </span>
          ภาษาไทย
        </MenuItem>
      </Select>
    ]
  }

  render() {
    return (
      <PageLayout
        title={this.props.t('edit')}
        isLoading={this.props.category.isEmpty()}
        secondaryActions={this.actions()}
        back={`/categories/${this.props.match.params.id}`}
      >
        <CategoryForm
          category={this.props.category}
          push={this.props.push}
          onSubmit={this.handleSubmit}
        />
      </PageLayout>
    )
  }
}

export default compose(
  withLocalizedFields,
  withTranslation('categories')
)(CategoryEditPage)
