/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import dayjs from 'dayjs'
import { useTranslation } from 'react-i18next'
import { Grid } from '@material-ui/core'

import './slotbtn.scss'

const index = ({ action, time, setTime }) => {
  const { t } = useTranslation(['reschedule'])

  const selectDate = (id, startTime, endTime) => {
    setTime((prevTime) => {
      const updatedSlot = prevTime.slot.map((slot) => {
        if (slot.id === id) {
          return {
            ...slot,
            active: true
          }
        } else {
          return {
            ...slot,
            active: false
          }
        }
      })

      return {
        ...prevTime,
        slot: updatedSlot
      }
    })

    action.setFieldValue('start_time_select', startTime)
    action.setFieldValue('end_time_select', endTime)
  }

  return (
    <div>
      <Grid container spacing={3}>
        {time.slot.map((data, k) => {
          return (
            <Grid item xs={12} md={6} lg={6} xl={4} key={k}>
              <div
                className={`section-btn-slot-job ${
                  data.disableBtn || (data.remain <= 0 && 'disable-slot-btn-job')
                } ${data.active && 'active-slot-btn-job'}`}
                onMouseDown={(e) =>
                  data.disableBtn || data.remain <= 0
                    ? e.stopPropagation()
                    : selectDate(data.id, data.start_time, data.end_time)
                }
              >
                <div>
                  <span className='inside-time-slot-text'>
                    {dayjs(data.start_time).format('HH:mm')} -
                  </span>
                  <span className='inside-time-slot-text'>
                    {dayjs(data.end_time).format('HH:mm')}
                  </span>
                  <p
                    className={`inside-time-slot-text ${
                      data.remain <= 5 ? 'low-remain' : 'normal-remain'
                    }`}
                  >
                    {data.remain} {t('available')}
                  </p>
                </div>
              </div>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default index
