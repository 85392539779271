/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useRef, useContext, createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import LocaleContext from 'contexts/LocaleContext'
import { useDispatch, useSelector } from 'react-redux'

import Tooltip from '@material-ui/core/Tooltip'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import { LocalizedField } from 'components/fields/Field'
import {
  Button,
  Typography,
  TextField,
  IconButton,
  ClickAwayListener,
  Paper,
  MenuList,
  MenuItem,
  Grow,
  Popper,
  CircularProgress,
  Grid
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { MoreVert } from '@material-ui/icons'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { Formik, Form, FieldArray, ErrorMessage } from 'formik'
import * as yup from 'yup'
import createStore from 'createStore'

import RichTextInput from '../inputs/RichTextInput'
import Modal from 'components/containers/Modal'
import { callService } from 'utils/callService'
import SetUpFees from './setupFee'
import SetupBaseFee from './SetupBaseFee'
import CircularLoader from 'components/CircularLoader'
import { ServiceFormContext } from 'forms/resources/ServiceForm/ServiceForm'

// import CancellationFormSection from './cancellationFormSection'

export const store = createStore()

const useStyles = makeStyles((theme) => ({
  arrow: {
    color: '#2d95f4'
  },
  tooltip: {
    backgroundColor: '#2d95f4',
    fontSize: '14px'
  }
}))

const index = (props) => {
  const { update, tenant, service } = props
  const { t } = useTranslation('common')
  const [UpFeesOpen, setUpFeesOpen] = useState(true)
  const [baseFees, setUpBaseFees] = useState(true)
  const locale = useContext(LocaleContext)
  const [open, setOpen] = useState(false)
  const [openDeleteModal, setOpenDeleteModal] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [viewMore] = useState(false)
  const [openModalSuccess, setOpenModalSuccess] = useState(false)
  const dispatch = useDispatch()
  const [isTextAreaEnabled, setIsTextAreaEnabled] = useState(true)
  const [isCheckboxEnabled, setIsCheckboxEnabled] = useState(true)
  const [isShowAddChoiceField, setIsShowAddChoiceField] = useState(true)
  const [currentActionMenu, setCurrentActionMenu] = useState()
  const [reasonOptions, setReasonOptions] = useState([])
  const [selectedReasons, setSelectedReasons] = useState([])
  const [editReasonIndex, setEditReasonIndex] = useState()
  const focusRef = useRef(null)
  const formRef = useRef(null)
  const [elRefs, setElRefs] = useState([])
  const params = useParams()
  const serviceID = params.id
  const [activeChoice, setActiveChoice] = useState()
  const [formMethod, setFormMethod] = useState()
  const [isLoading, setIsLoading] = useState(true)
  const [isSaveServiceLoading, setIsSaveServiceLoading] = useState(false)
  const [initialValues, setInitialValues] = useState({
    compliance_details_th: '',
    compliance_details_en: '',
    is_published: true,
    is_condition_fee_enabled: true,
    is_base_condition_fee_enabled: true,
    base_condition_fee_type: 'percent',
    base_condition_fee_value: 0,
    is_reason_choice_enabled: true,
    is_reason_other_enabled: true,
    title_reason_choice_th: '',
    title_reason_choice_en: '',
    title_reason_other_th: '',
    title_reason_other_en: '',
    service_id: serviceID,
    rescheduleConditionFees: [],
    rescheduleReasonChoices: [],
    base_fees: '',
    create_at: null
  })
  const { setConditionFeePublishDate, setEnableReschedule, enableReschedule } =
    useContext(ServiceFormContext)

  const tenant_id = useSelector(
    (state) => state.getIn(['entities']).toJS().clients[5].tenant_id
  )

  useEffect(() => {
    // add or remove refs
    setElRefs((elRefs) =>
      Array(selectedReasons.length)
        .fill()
        .map((_, i) => elRefs[i] || createRef())
    )

    if (selectedReasons.length === 0) {
      setIsShowAddChoiceField(true)
    }
  }, [selectedReasons.length])

  useEffect(() => {
    fetchReschedule()
    fetchRescheduleReasonOptions()
  }, [])

  useEffect(() => {
    //clear active choice when field is not display
    isShowAddChoiceField === false && setActiveChoice()
  }, [isShowAddChoiceField])

  const classes = useStyles()

  const ValidationSchema = yup.object({
    rescheduleConditionFees: yup
      .array()
      .of(
        yup.object().shape({
          hrs: yup
            .string()
            .required('Hrs is required.')
            .max(2, 'hrs must be at least 2 characters.')
            .matches(/^[0-9]*$/, 'hrs Invalid format')
            .nullable(),
          min: yup
            .string()
            .required('Min is required.')
            .max(2, 'min must be at least 2 characters.')
            .matches(/^[0-9]*$/, 'min Invalid format')
            .nullable(),
          value: yup
            .string()
            .required('Value is required.')
            .matches(/^[.0-9]*$/, 'Set Fee Invalid format')
            // .matches(/^[0-9]*(\.[0-9]{0,2})?$/, 'Set Fee Invalid format')
            .nullable(),
          setFee: yup.string().nullable()
        })
      )
      .required('Setup Fees is required.')
      .nullable()
  })

  function reverseCalculate(data) {
    const hrs = Math.floor(data.before_minutes / 60)
    const min = data.before_minutes % 60
    return { hrs: hrs, min: min, setFee: data.type, value: data.value }
  }

  const fetchReschedule = async () => {
    try {
      setIsLoading(true)
      const response = await callService.get(
        `${process.env.REACT_APP_RESCHEDULE_API}/services/${serviceID}/reschedule`
      )
      if (response.status === 200) {
        setIsLoading(false)
        let data = response.data
        let reformatedReasonChoices = data.reschedule_reason_choices.map(
          (item) => item.reschedule_reasons
        )

        if (data) {
          const initialData = {
            ...initialValues,
            ...data,
            rescheduleConditionFees: data.reschedule_condition_fees.map((item) =>
              reverseCalculate(item)
            )
          }
          setConditionFeePublishDate(data.published_at)
          setUpFeesOpen(data.is_condition_fee_enabled)
          setUpBaseFees(data.is_base_condition_fee_enabled)
          setIsCheckboxEnabled(data.is_reason_choice_enabled)
          setIsTextAreaEnabled(data.is_reason_other_enabled)
          setSelectedReasons(reformatedReasonChoices)
          setInitialValues(initialData)
          setEnableReschedule(data.is_published)
        }
      }
    } catch (error) {
      alert(`An error occurred while fetch the reschedule: ${error.message}`)
    }
  }

  const createRescheduleReason = async (data) => {
    try {
      const response = await callService.post(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-reasons/tenant/${tenant_id}`,
        data
      )
      if (response.data) {
        setSelectedReasons([...selectedReasons, response.data])
      }
      fetchRescheduleReasonOptions()
      setActiveChoice()
    } catch (error) {
      alert(`An error occurred while deleting the reschedule reason: ${error.message}`)
    }
  }

  const fetchRescheduleReasonOptions = async () => {
    try {
      const response = await callService.get(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-reasons/tenant/${tenant_id}`
      )
      setReasonOptions(response.data)
      // return response
    } catch (error) {
      alert(`An error occurred while deleting the reschedule reason: ${error.message}`)
    }
  }

  const updateExistingChoice = async (data, reason_id, index) => {
    delete data.id

    try {
      const response = await callService.patch(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-reasons/reason/${reason_id}`,
        data
      )
      if (response.status === 200) {
        const tempSelectedReason = [...selectedReasons]
        tempSelectedReason[index] = response.data
        setSelectedReasons(tempSelectedReason)
      }
      return response
    } catch (error) {
      alert(`An error occurred while deleting the reschedule reason: ${error.message}`)
    }
  }

  const deleteRescheduleReason = async (ReasonId) => {
    try {
      const response = await callService.delete(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-reasons/reason/${ReasonId}`
      )
      if (response.status === 204 || response.status === 200) {
        const filteredReason = selectedReasons.filter((item) => item.id !== ReasonId)
        setSelectedReasons(filteredReason)
        setActiveChoice()
        fetchRescheduleReasonOptions()
        setOpenDeleteModal((prev) => !prev)
        setCurrentActionMenu()
      }
      return response
    } catch (error) {
      alert(`An error occurred while deleting the reschedule reason: ${error.message}`)
    }
  }

  const handleUpdateReason = (e, reason, index) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      const data = { ...reason, [e.target.name]: e.target.value }
      delete data.tenant_id
      delete data.deleted_at
      updateExistingChoice(data, data.id, index)
      setEditReasonIndex()
    }
  }

  const handleDeleteReason = (id) => {
    deleteRescheduleReason(id)
  }

  const handleRemoveReason = (id) => {
    const filteredReason = selectedReasons.filter((item) => item.id !== id)
    setSelectedReasons(filteredReason)
    setCurrentActionMenu()
  }

  const handleShowAction = (event, index, ref) => {
    if (ref[index].current && ref[index].current.contains(event.target)) {
      return
    }

    setCurrentActionMenu()
  }

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault()
      setCurrentActionMenu()
    }
  }

  const handleAddChoice = (e) => {
    if (e.key === 'Enter' && e.target.value.trim()) {
      if (activeChoice) {
        setSelectedReasons([...selectedReasons, activeChoice])
        setIsShowAddChoiceField((prev) => !prev)
      } else {
        createNewChoice(e)
      }
    }
  }

  const handleDisplayReason = (reason_obj) => {
    const fallbackLocale = locale === 'en' ? 'th' : 'en'
    return reason_obj[`name_${locale}`] || reason_obj[`name_${fallbackLocale}`]
  }

  const createNewChoice = (e) => {
    const choiceData = { name_th: '', name_en: '' }

    createRescheduleReason({ ...choiceData, [`name_${locale}`]: e.target.value })
    setIsShowAddChoiceField((prev) => !prev)
  }

  const switchUpdate = (action) => {
    if (action.values.create_at) {
      update(true)
    } else {
      update(false)
    }
  }

  const saveCondition = async (data) => {
    let url = process.env.REACT_APP_RESCHEDULE_API + `/reschedules/${initialValues.id}`

    try {
      const response = await callService({
        method: 'PATCH',
        url: url,
        data: data
      })

      if (response.status === 201 || response.status === 200) {
        setIsSaveServiceLoading((prev) => !prev)
        setOpenModal(false)
        setOpenModalSuccess(true)
        fetchReschedule()
        setTimeout(() => {
          setOpenModalSuccess(false)
        }, 3000)
      }
    } catch (error) {
      alert(`An error occurred while save the reschedule: ${error.message}`)
    }
  }

  const saveService = (formik) => {
    setIsSaveServiceLoading((prev) => !prev)
    const valueFees = formik.rescheduleConditionFees
      .filter((data) => {
        if (data.hrs === '0' && data.min === '0' && data.value.length <= 0) {
          return null
        }
        return data
      })
      .map((items) => {
        const calHrs = parseInt(items.hrs) * 60
        const calMin = parseInt(items.min)

        return {
          before_minutes: parseInt(calHrs + calMin),
          type: items.setFee,
          value: parseInt(items.value) || 0
        }
      })

    //reformat selectedReason object
    const reformatedSelectedReason = selectedReasons.map((item) => {
      return item.id
    })

    const SetData = {
      // id: formik.id,
      compliance_details_th: formik.compliance_details_th,
      compliance_details_en: formik.compliance_details_en,
      is_condition_fee_enabled: UpFeesOpen,
      is_base_condition_fee_enabled: baseFees,
      base_condition_fee_type: formik.base_condition_fee_type,
      base_condition_fee_value: parseInt(formik.base_condition_fee_value),
      is_reason_choice_enabled: isCheckboxEnabled,
      is_reason_other_enabled: isTextAreaEnabled,
      title_reason_choice_th: formik.title_reason_choice_th,
      title_reason_choice_en: formik.title_reason_choice_en,
      title_reason_other_th: formik.title_reason_other_th,
      title_reason_other_en: formik.title_reason_other_en,
      // service_id: parseInt(serviceID),
      // tenant_id: tenant,
      reschedule_condition_fees: valueFees,
      reschedule_reason_choices: reformatedSelectedReason,
      is_published: enableReschedule
    }

    saveCondition(SetData)
  }

  const callFocus = () => {
    window.scrollTo({
      top: focusRef.current?.offsetTop,
      left: 0,
      behavior: 'smooth'
    })
  }

  const handleOpenEditReason = (index) => {
    setEditReasonIndex(index)
    setCurrentActionMenu()
  }

  return (
    <div>
      <div>
        {isLoading ? (
          <CircularLoader />
        ) : (
          <Formik
            enableReinitialize
            innerRef={formRef}
            initialValues={initialValues}
            onSubmit={saveService}
            validationSchema={ValidationSchema}
          >
            {(formik) => {
              return (
                <Form>
                  <div>
                    <div className='header-topic-section-custom'>
                      <div className='reschedule-main-topic' style={{ marginBottom: 24 }}>
                        {t('setup_conditions')}
                      </div>
                      <div className='topic'>{t('setup_reschedule_condition_title')}</div>
                      <div className='title'>{`${t(
                        'setup_reschedule_condition_title_hint'
                      )}`}</div>
                    </div>
                    <div className='section-compliance-details'>
                      <div className='d-flex align-item-center mb-1'>
                        <div className='section-text-box-custom-text'>
                          {t('compliance_details')}
                        </div>
                        <div className='fee-icon'>
                          <Tooltip
                            title={t('reschedule_message.compliance_details_tooltip')}
                            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                            placement='right-start'
                            arrow
                          >
                            <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                          </Tooltip>
                        </div>
                      </div>
                      <LocalizedField
                        noLabel
                        name='compliance_details'
                        value={formik.values[`compliance_details_${locale}`]}
                        component={RichTextInput}
                      />
                    </div>

                    <div className='scroll-margin-top'>
                      <div className='d-flex align-item-center' ref={focusRef}>
                        <div className='topic mr-1'>
                          {t('setup_reschedule_condition_reasons')}
                        </div>
                        <div className='fee-icon'>
                          <Tooltip
                            title={t('reschedule_message.reschedule_reasons_tooltip')}
                            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                            placement='right-start'
                            arrow
                          >
                            <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                          </Tooltip>
                        </div>
                      </div>
                      <div className='mb-3 mt-3'>
                        <div>
                          <div>
                            {/* ================ Checkbox ============================================== */}
                            <div>
                              <div className='enable-switch'>
                                <Typography className='switch-input-label'>
                                  {t('checkbox')}
                                </Typography>
                                <div className='switch-input-container'>
                                  <label className='switch'>
                                    <input
                                      type='checkbox'
                                      checked={isCheckboxEnabled}
                                      onChange={() => {
                                        switchUpdate(formik)
                                        setIsCheckboxEnabled((prev) => !prev)
                                      }}
                                    />
                                    <span className='slider round' />
                                  </label>
                                </div>
                                <div className='switch-status'>
                                  {t(`is_enabled.${isCheckboxEnabled}`)}
                                </div>
                              </div>
                              <div className='section-choice field-set'>
                                <div className='input-field-text-area'>
                                  <div className='topic-display-custom-section pb-1'>
                                    {t('title')}
                                  </div>
                                  <LocalizedField
                                    name='title_reason_choice'
                                    placeholder='Add Title'
                                    disabled={!isCheckboxEnabled}
                                    noLabel
                                    noHint
                                  />
                                </div>
                                <div className='mb-2'>
                                  <div className='topic-display-custom-section pb-1 pt-1'>
                                    Choices
                                  </div>
                                </div>
                                {selectedReasons.length !== 0 && (
                                  <div className='choice-item-container'>
                                    {selectedReasons.map((item, index) => (
                                      <div
                                        className='choice-item'
                                        key={`choice-item-${index}`}
                                      >
                                        {editReasonIndex === index ? (
                                          <TextField
                                            variant='outlined'
                                            size='small'
                                            name={`name_${locale}`}
                                            fullWidth
                                            onKeyDown={(e) =>
                                              handleUpdateReason(e, item, index)
                                            }
                                          />
                                        ) : (
                                          <Typography>
                                            {handleDisplayReason(item)}
                                          </Typography>
                                        )}
                                        <IconButton
                                          aria-label='more'
                                          disabled={!isCheckboxEnabled}
                                          ref={elRefs[index]}
                                          aria-controls={
                                            open ? 'menu-list-grow' : undefined
                                          }
                                          aria-haspopup='true'
                                          onClick={() => setCurrentActionMenu(index)}
                                        >
                                          <MoreVert fontSize='small' />
                                        </IconButton>
                                        <Popper
                                          style={{ zIndex: 99 }}
                                          open={currentActionMenu === index}
                                          anchorEl={elRefs[index]?.current}
                                          role={undefined}
                                          transition
                                          disablePortal
                                        >
                                          {({ TransitionProps, placement }) => (
                                            <Grow
                                              {...TransitionProps}
                                              style={{
                                                transformOrigin:
                                                  placement === 'bottom'
                                                    ? 'left top'
                                                    : 'left bottom'
                                              }}
                                            >
                                              <ClickAwayListener
                                                onClickAway={(e) =>
                                                  handleShowAction(e, index, elRefs)
                                                }
                                              >
                                                <Paper>
                                                  <MenuList
                                                    // autoFocusItem={currentActionMenu}
                                                    id='menu-list-grow'
                                                    onKeyDown={handleListKeyDown}
                                                  >
                                                    {
                                                      //reason must be empty to edit
                                                      item[`name_${locale}`] === '' && (
                                                        <MenuItem
                                                          onClick={() =>
                                                            handleOpenEditReason(index)
                                                          }
                                                        >
                                                          {t('edit')}
                                                        </MenuItem>
                                                      )
                                                    }
                                                    <MenuItem
                                                      className='remove-choice-btn'
                                                      onClick={() =>
                                                        handleRemoveReason(item.id)
                                                      }
                                                    >
                                                      {t('remove_choice')}
                                                    </MenuItem>
                                                    <MenuItem
                                                      className='delete-choice-btn'
                                                      onClick={() => {
                                                        setOpenDeleteModal(
                                                          (prev) => !prev
                                                        )
                                                      }}
                                                    >
                                                      {t('delete_choice')}
                                                    </MenuItem>
                                                  </MenuList>
                                                  {/* ---- Delete Confirmation Modal------------------------------ */}
                                                  <Modal
                                                    isOpen={openDeleteModal}
                                                    onCloseModal={() =>
                                                      setOpenDeleteModal(false)
                                                    }
                                                    className='section-custom-main-modal'
                                                  >
                                                    <div className='custom-padding-modal'>
                                                      <div className='text-center'>
                                                        <div className='modal-topic'>
                                                          {t(
                                                            'delete_reason_modal.confirm_message',
                                                            {
                                                              reason:
                                                                item[`name_${locale}`]
                                                            }
                                                          )}
                                                        </div>
                                                      </div>
                                                      <div className='d-flex align-item-center justify-content-center mt-2'>
                                                        <div
                                                          className='custom-btn-cancel-modal-2 d-flex align-item-center justify-content-center pointer'
                                                          onClick={() =>
                                                            setOpenDeleteModal(
                                                              (pre) => !pre
                                                            )
                                                          }
                                                        >
                                                          {t('delete_reason_modal.no')}
                                                        </div>
                                                        <div
                                                          className='custom-btn-cancel-modal-1 d-flex align-item-center justify-content-center pointer'
                                                          onClick={() =>
                                                            handleDeleteReason(item.id)
                                                          }
                                                        >
                                                          {t('delete_reason_modal.yes')}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Modal>
                                                </Paper>
                                              </ClickAwayListener>
                                            </Grow>
                                          )}
                                        </Popper>
                                      </div>
                                    ))}
                                  </div>
                                )}

                                {isShowAddChoiceField && (
                                  <div className='custom-section-choices'>
                                    <Autocomplete
                                      options={reasonOptions}
                                      getOptionLabel={(option) =>
                                        handleDisplayReason(option)
                                      }
                                      autoSelect
                                      fullWidth
                                      disableClearable
                                      disabled={!isCheckboxEnabled}
                                      onChange={(e, newValue) =>
                                        setActiveChoice(newValue)
                                      }
                                      value={activeChoice}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          className='add-choice-field'
                                          fullWidth
                                          placeholder='Add Reason'
                                          size='small'
                                          variant='outlined'
                                          onChange={() => setActiveChoice()}
                                          onKeyDown={handleAddChoice}
                                          InputProps={{
                                            ...params.InputProps,
                                            endAdornment: null
                                          }}
                                        />
                                      )}
                                    />
                                  </div>
                                )}
                                <div className='add-choice-btn-container'>
                                  <Button
                                    style={{ textTransform: 'none' }}
                                    color='primary'
                                    onClick={() => setIsShowAddChoiceField(true)}
                                    startIcon={<AddCircleOutlineIcon />}
                                  >
                                    <span>Add Choice</span>
                                  </Button>
                                </div>
                              </div>
                            </div>

                            {/* ================ Text Area ============================================== */}
                            <div id='textarea-fieldset' className='mt-3'>
                              <div className='enable-switch'>
                                <Typography className='switch-input-label'>
                                  {t('other_reason_textarea')}
                                </Typography>
                                <div className='switch-input-container'>
                                  <label className='switch'>
                                    <input
                                      type='checkbox'
                                      checked={isTextAreaEnabled}
                                      onChange={() => {
                                        switchUpdate(formik)
                                        setIsTextAreaEnabled((prev) => !prev)
                                      }}
                                    />
                                    <span className='slider round' />
                                  </label>
                                </div>
                                <div className='switch-status'>
                                  {t(`is_enabled.${isTextAreaEnabled}`)}
                                </div>
                              </div>
                              <div className='input-field-text-area field-set'>
                                <div className='topic-display-custom-section pb-1'>
                                  {t('title')}
                                </div>
                                <LocalizedField
                                  name='title_reason_other'
                                  placeholder='Add title here'
                                  disabled={!isTextAreaEnabled}
                                  noLabel
                                  noHint
                                />
                              </div>
                            </div>

                            {/* {checkRender()} */}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className='mt-3'>
                      <div className='title-and-switch-set-up-fee-custom'>
                        <div className='d-flex align-item-center'>
                          <div className='topic mr-1'>{t('setup_condition_fees')}</div>
                          <div className='fee-icon'>
                            <Tooltip
                              title={t('reschedule_message.setup_fees_tooltip')}
                              classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                              placement='right-start'
                              arrow
                            >
                              <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                            </Tooltip>
                          </div>
                        </div>
                        <div className='section-switch'>
                          <div>
                            <label className='switch'>
                              <input
                                type='checkbox'
                                checked={UpFeesOpen}
                                onChange={() => {
                                  switchUpdate(formik)
                                  setUpFeesOpen((pre) => !pre)
                                }}
                              />
                              <span className='slider round' />
                            </label>
                          </div>
                          <div className='text-switch'>
                            {t(`is_charge.${UpFeesOpen}`)}
                          </div>
                        </div>
                      </div>
                      {UpFeesOpen && (
                        <FieldArray name='rescheduleConditionFees'>
                          {(arrayHelpers) => (
                            <div className='mt-2'>
                              <div className='d-flex'>
                                <div className='d-flex align-item-center'>
                                  <div className='fee-topic-font'>
                                    {t('set_time_limit')}
                                  </div>
                                  <div className='fee-icon'>
                                    <Tooltip
                                      title={t(
                                        'reschedule_message.set_time_limit_tooltip'
                                      )}
                                      placement='right-start'
                                      classes={{
                                        arrow: classes.arrow,
                                        tooltip: classes.tooltip
                                      }}
                                      arrow
                                    >
                                      <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className='d-flex align-item-center custom-set-fee-by'>
                                  <div className='fee-topic-font'>{t('set_fee_by')}</div>
                                  <div className='fee-icon'>
                                    <Tooltip
                                      title={t('reschedule_message.set_fee_by_tooltip')}
                                      classes={{
                                        arrow: classes.arrow,
                                        tooltip: classes.tooltip
                                      }}
                                      placement='right-start'
                                      arrow
                                    >
                                      <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                                    </Tooltip>
                                  </div>
                                </div>
                              </div>
                              <div className='mt-1'>
                                {formik.values.rescheduleConditionFees.length > 0 &&
                                  formik.values.rescheduleConditionFees.map((data, k) => (
                                    <div key={k}>
                                      <SetUpFees
                                        func={arrayHelpers}
                                        id={k}
                                        action={formik}
                                        formikName={`rescheduleConditionFees.${k}.setFee`}
                                        rawData={data}
                                        switchUpdate={update}
                                      />
                                    </div>
                                  ))}
                              </div>

                              {formik.values.rescheduleConditionFees.length <= 0 && (
                                <div className='text-validate'>
                                  <ErrorMessage
                                    name='rescheduleConditionFees'
                                    component='div'
                                    className='field-error'
                                  />
                                </div>
                              )}

                              <div className='button-add-more-set-up-fee mt-2 d-flex align-item-center'>
                                <div
                                  className='icon-plus pointer'
                                  onClick={() => {
                                    switchUpdate(formik)
                                    arrayHelpers.push({
                                      hrs: '0',
                                      min: '0',
                                      value: '0',
                                      setFee: 'percent'
                                    })
                                  }}
                                >
                                  +
                                </div>
                                <div
                                  className='text-add-custom pointer'
                                  onClick={() => {
                                    switchUpdate(formik)
                                    arrayHelpers.push({
                                      hrs: '0',
                                      min: '0',
                                      value: '0',
                                      setFee: 'percent'
                                    })
                                  }}
                                >
                                  Add More
                                </div>
                              </div>
                            </div>
                          )}
                        </FieldArray>
                      )}
                    </div>

                    <div className='mt-3'>
                      <div className='d-flex align-item-center'>
                        <div className='topic mr-1'>{t('setup_condition_base_fees')}</div>
                        <div className='fee-icon'>
                          <Tooltip
                            title={t(
                              'reschedule_message.setup_miscellaneous_fee_tooltip'
                            )}
                            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                            placement='right-start'
                            arrow
                          >
                            <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                          </Tooltip>
                        </div>
                        <div className='section-switch pl-2'>
                          <div>
                            <label className='switch'>
                              <input
                                type='checkbox'
                                checked={baseFees}
                                onChange={() => {
                                  switchUpdate(formik)
                                  setUpBaseFees((pre) => !pre)
                                  setOpen(false)
                                }}
                              />
                              <span className='slider round' />
                            </label>
                          </div>
                          <div className='text-switch'>{t(`is_enabled.${baseFees}`)}</div>
                        </div>
                      </div>
                      <div className='section-buttn-basic-fee'>
                        <div className='mt-2'>
                          <div className='section-select-choice custom-bottom'>
                            <SetupBaseFee
                              action={formik}
                              rawData={formik.values}
                              switchUpdate={update}
                            />
                          </div>
                        </div>
                        <div>
                          <Button
                            variant='contained'
                            color='primary'
                            onClick={() => setOpenModal((pre) => !pre)}
                          >
                            {t('save_condition')}
                          </Button>
                        </div>
                      </div>
                    </div>

                    <Modal
                      isOpen={openModal}
                      onCloseModal={() => !isSaveServiceLoading && setOpenModal(false)}
                      className='section-custom-main-modal'
                    >
                      <div className='custom-padding-modal'>
                        <div className='text-center'>
                          <div className='modal-topic'>
                            {t('confirm_save_condition_modal.reschedule_message', {
                              service_name: service.toJS()[`name_${locale}`]
                            })}
                          </div>
                          <div className='modal-content mt-3'>
                            {t('confirm_save_condition_modal.condition_message')}
                          </div>
                        </div>
                        <div className='d-flex align-item-center justify-content-center mt-2'>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                variant='outlined'
                                color='primary'
                                style={{ height: 45 }}
                                fullWidth
                                disabled={isSaveServiceLoading}
                                onClick={() => setOpenModal((pre) => !pre)}
                              >
                                {t('confirm_save_condition_modal.cancel')}
                              </Button>
                            </Grid>
                            <Grid item xs={6}>
                              <Button
                                className={`custom-btn-cancel-modal-1`}
                                variant='contained'
                                color='primary'
                                style={{ height: 45 }}
                                disabled={isSaveServiceLoading}
                                onClick={() =>
                                  !isSaveServiceLoading && saveService(formik.values)
                                }
                              >
                                {isSaveServiceLoading ? (
                                  <CircularProgress size={24} />
                                ) : (
                                  t('confirm_save_condition_modal.continue')
                                )}
                              </Button>
                            </Grid>
                          </Grid>
                        </div>
                      </div>
                    </Modal>
                  </div>
                </Form>
              )
            }}
          </Formik>
        )}
        {viewMore && (
          <div className='up-to-top'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='25.336'
              height='25.336'
              viewBox='0 0 25.336 25.336'
              className='pointer'
              onClick={() => callFocus()}
            >
              <path
                id='Icon_material-arrow_back'
                data-name='Icon material-arrow_back'
                d='M25.336,14.251H6.065L14.917,23.1l-2.249,2.233L0,12.668,12.668,0,14.9,2.233,6.065,11.084H25.336Z'
                transform='translate(25.336) rotate(90)'
                fill='#1b4171'
              />
            </svg>
          </div>
        )}
      </div>
      <Modal
        isOpen={openModalSuccess}
        onCloseModal={() => setOpenModalSuccess(false)}
        className='section-custom-main-modal-success'
      >
        <div className='d-flex align-item-center justify-content-between'>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='32.94'
              height='32.94'
              viewBox='0 0 32.94 32.94'
            >
              <path
                d='M33.5,17.032A16.47,16.47,0,1,1,17.032.562,16.47,16.47,0,0,1,33.5,17.032ZM15.127,25.753l12.22-12.22a1.063,1.063,0,0,0,0-1.5l-1.5-1.5a1.063,1.063,0,0,0-1.5,0l-9.966,9.965L9.723,15.841a1.063,1.063,0,0,0-1.5,0l-1.5,1.5a1.063,1.063,0,0,0,0,1.5l6.907,6.907a1.063,1.063,0,0,0,1.5,0Z'
                transform='translate(-0.562 -0.562)'
                fill='#1b4171'
              />
            </svg>
          </div>
          <div className='topic-success-modal'>{t('reschedule_success_message')}</div>
        </div>
      </Modal>
    </div>
  )
}

export default index
