import React from 'react'
import PropTypes from 'prop-types'
import { range } from 'lodash'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import moment from 'moment'
import classNames from 'clsx'

import styles from './TimePickerNewStyles'

export function TimePickerNew({
  hour: hourProp,
  minute: minuteProp,
  highlightedTimes,
  minuteDuration,
  onChange,
  classes,
  ...rest
}) {
  function setTime(time) {
    onChange && onChange(time)
  }

  function isHighlighted({ hour, minute }) {
    const time = moment().hour(hour).minute(minute)

    return highlightedTimes.reduce((result, { start, end }) => {
      const highlightedStartTime = moment().hour(start.hour).minute(start.minute)
      const highlightedEndTime = moment().hour(end.hour).minute(end.minute)

      return (
        (time.isSameOrAfter(highlightedStartTime) && time.isBefore(highlightedEndTime)) ||
        result
      )
    }, false)
  }

  function isInDuration({ hour, minute }) {
    const time = moment().hour(hour).minute(minute)
    const durationStart = moment().hour(hourProp).minute(minuteProp)
    const durationEnd = moment()
      .hour(hourProp)
      .minute(minuteProp)
      .add(minuteDuration, 'minutes')

    return time.isBetween(durationStart, durationEnd)
  }

  return (
    <div className={classes.container}>
      {range(6, 22).map((hour) => (
        <div className={classes.hour} key={hour}>
          {[0, 30].map((minute) => {
            const isSelected = hour === hourProp && minute === minuteProp

            return (
              <Button
                key={`${hour}.${minute}`}
                type='button'
                onClick={() => setTime({ hour, minute })}
                variant='outlined'
                className={classNames(classes.defaultButton, {
                  [classes.selectedButton]: isSelected,
                  [classes.isInDuration]: minuteDuration && isInDuration({ hour, minute })
                })}
                classes={{ disabled: isSelected && classes.disabledButton }}
                {...rest}
              >
                {hour}:{minute.toString().padStart(2, '0')}
                {highlightedTimes && isHighlighted({ hour, minute }) && (
                  <span className={classes.highlightDot} />
                )}
              </Button>
            )
          })}
        </div>
      ))}
    </div>
  )
}

TimePickerNew.propTypes = {
  hour: PropTypes.number,
  minute: PropTypes.number,
  onChange: PropTypes.func,
  classes: PropTypes.object,
  highlightedTimes: PropTypes.arrayOf(PropTypes.object),
  minuteDuration: PropTypes.number
}

export default withStyles(styles)(TimePickerNew)
