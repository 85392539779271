/* eslint-disable camelcase */
import React from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'clsx'
import { serviceSchema, slotSchema } from '@seekster/schemas'
import { Grid } from '@material-ui/core'
import { hourFormat } from 'utils/dateFormat'
import { useCollectionChildren } from 'hooks/collections'

import './SlotTimeField.scss'
import moment from 'moment'

function SlotTime({ className, label, remain, t, readOnly, onClick }) {
  return (
    <Grid item xs={6} sm={4}>
      <button
        className={className}
        type='button'
        onClick={onClick}
        disabled={!remain || readOnly || remain <= 0}
      >
        {label}
        <p className={classNames([remain > 5 && 'available'])}>
          {t(`form.date_time.slot_time_available.${!!remain && remain > 0}`, {
            remain: Math.max(remain, 0)
          })}
        </p>
      </button>
    </Grid>
  )
}

function SlotTimeField({ serviceId, value, currentDate, readOnly, handleSlotChange }) {
  const date = moment(currentDate).format()
  const startTime = moment.duration(hourFormat(value?.start_time)).asMinutes()
  const endTime = moment.duration(hourFormat(value?.end_time)).asMinutes()

  const { t } = useTranslation('inquiries')
  const [slots] = useCollectionChildren(
    serviceSchema,
    serviceId,
    slotSchema,
    {
      queryParam: {
        date,
        order: {
          start_time: 'desc'
        }
      }
    },
    [moment(date).format('YYYY MM DD')]
  )

  return (
    <Grid container spacing={2} className='slot-time-container'>
      {slots
        .sortBy((slot) => {
          const start_time = slot.get('start_time')
          const hours = moment(start_time).hours()
          const minutes = moment(start_time).minutes()
          return moment().set({ hours, minutes })
        })
        .map((slot) => {
          const slotStartTime = moment
            .duration(hourFormat(slot.get('start_time')))
            .asMinutes()
          const slotEndTime = moment
            .duration(hourFormat(slot.get('end_time')))
            .asMinutes()
          const active =
            value?.start_time && value?.end_time
              ? startTime >= slotStartTime && endTime <= slotEndTime
              : false

          return (
            <SlotTime
              key={slot.get('id')}
              className={classNames([active && 'active'])}
              label={`${hourFormat(slot.get('start_time'))} - ${hourFormat(
                slot.get('end_time')
              )}`}
              readOnly={readOnly}
              onClick={() =>
                handleSlotChange(
                  hourFormat(slot.get('start_time')),
                  hourFormat(slot.get('end_time'))
                )
              }
              remain={slot.get('remain')}
              t={t}
            />
          )
        })}
    </Grid>
  )
}

export default SlotTimeField
