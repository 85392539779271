import React, { useEffect, useState } from 'react'
import { Grid } from '@material-ui/core'
import { fromJS } from 'immutable'


import EventTimeline from 'modules/events/EventTimeline'
import { callService } from 'utils/callService'
import { useParams } from 'react-router-dom'

export function InvoiceTimelinePage() {
  const { id } = useParams()
  const [events, setEvents] = useState([])

  useEffect(() => {
    fetchInvoiceEvents(id)
  }, [])

  const fetchInvoiceEvents = async (invoice_id) => {
    try {
      const response = await callService.get(`${process.env.REACT_APP_BASE_API}/invoices/${invoice_id}/events`)
      setEvents(response.data ?? [])
      return response
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Grid container>
      <Grid item xs={12} md={8}>
        <EventTimeline events={fromJS(events)} />
      </Grid>
    </Grid>
  )
}

export default InvoiceTimelinePage

