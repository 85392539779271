/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'
import { useResource } from 'hooks/resources'

import ServicesTable from 'components/tables/ServicesTable'
import Panel from 'components/containers/Panel'

import { categorySchema } from '@seekster/schemas'

export function CategoryServicesPane({ match }) {
  const [category, { loading }] = useResource(categorySchema, match.params.id)
  const categorySerives = category.toJS().category_services

  const sortedCategory = category.toJS().services?.sort((a, b) => {
    const aServiceObj = categorySerives.find((item) => item.service_id === a.id)
    const bServiceObj = categorySerives.find((item) => item.service_id === b.id)
    const aServicePosition = aServiceObj ? aServiceObj.position : Infinity
    const bServicePosition = bServiceObj ? bServiceObj.position : Infinity
    const servicePositionDiff = aServicePosition - bServicePosition
    return servicePositionDiff !== 0 ? servicePositionDiff : a.position - b.position
  })

  return (
    <Panel title='Services'>
      <ServicesTable category={category} loading={loading} services={sortedCategory} />
    </Panel>
  )
}

export default CategoryServicesPane
