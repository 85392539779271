import React, { useState } from 'react'
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Collapse from '@material-ui/core/Collapse';
import Box from '@material-ui/core/Box';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Button, CardActions, Grid, IconButton, TextField, Tooltip } from '@material-ui/core';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import useFullPageLoader from 'hooks/useFullPageLoader';
import './InvoiceExpirationPane.scss'
import { useTranslation } from 'react-i18next';
import { callService } from 'utils/callService';

const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 900,
    margin: '10px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    padding: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop: '10px',
  },
  icon: {
    marginLeft: '5px',
  },
  arrow: {
    color: '#2d95f4'
  },
  tooltip: {
    backgroundColor: '#2d95f4',
    fontSize: '14px'
  }
});

const validationSchema = Yup.object().shape({
  countdown_before_job_start: Yup.object().shape({
    days: Yup.number().integer('Days must be an integer').min(0, 'Days must be positive'),
    hrs: Yup.number().integer('Hrs must be an integer').min(0, 'Hrs must be positive')
  }),
  countdown_after_invoice_created: Yup.object().shape({
    days: Yup.number().integer('Days must be an integer').min(0, 'Days must be positive'),
    hrs: Yup.number().integer('Hrs must be an integer').min(0, 'Hrs must be positive')
  }),
  minimum_advance_booking_time: Yup.object().shape({
    days: Yup.number().integer('Days must be an integer').min(0, 'Days must be positive').test(
      'greater-than-countdown-before-job-start',
      'Minimum advance booking time must be greater than or equal to countdown before job start',
      function (value) {
        const countdownBeforeJobStart = this.parent.countdown_before_job_start;
        const countdownBeforeJobStartInHrs = countdownBeforeJobStart.days * 24 + countdownBeforeJobStart.hrs;
        const minimumAdvanceBookingTimeInHrs = value.days * 24 + value.hrs;
        return minimumAdvanceBookingTimeInHrs >= countdownBeforeJobStartInHrs;
      }
    ),
    hrs: Yup.number().integer('Hrs must be an integer').min(0, 'Hrs must be positive')
  }),
  maximum_advance_booking_time: Yup.object().shape({
    days: Yup.number().integer('Days must be an integer').min(0, 'Days must be positive'),
    hrs: Yup.number().integer('Hrs must be an integer').min(0, 'Hrs must be positive')
  })
});

const initialValues = {
  countdown_before_job_start: { days: 0, hrs: 0 },
  countdown_after_invoice_created: { days: 0, hrs: 0 },
  minimum_advance_booking_time: { days: 0, hrs: 0 },
  maximum_advance_booking_time: { days: 0 }
}

function InvoiceExpirationPane() {
  const classes = useStyles();
  const [loader, showLoader, hideLoader] = useFullPageLoader()
  const [expandedItem, setExpandedItem] = useState({
    countdown_before_job_start: false,
    countdown_after_invoice_created: false,
    minimum_advance_booking_time: false,
    maximum_advance_booking_time: false
  })
  const { t } = useTranslation()

  const fetchServiceSettings = async () => {
    try {
      showLoader()
      const response = await callService.get(`${process.env.REACT_APP_RESCHEDULE_API}/invoice_expiration`)
      if (response.status === 200) {
        hideLoader()
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitData = async (data) => {
    try {
      showLoader()
      const response = await callService.patch(
        `${process.env.REACT_APP_RESCHEDULE_API}/invoice_expiration/${data.reschedule_acceptance_time.id}`,
        {
          "countdown_before_job_start": data.countdown_before_job_start,
          "countdown_after_invoice_created": data.countdown_after_invoice_created,
          "minimum_advance_booking_time": data.minimum_advance_booking_time,
          "maximum_advance_booking_time": data.maximum_advance_booking_time
        })
      if (response.status === 200) {
        hideLoader()
        // setOpenModal(false)
        return response
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleExpandClick = (expand_item_name) => {
    setExpandedItem({
      ...expandedItem,
      [expand_item_name]: !expandedItem[expand_item_name]
    })
  };

  const handleSubmit = () => {
    return
  }

  return (
    <div>
      {loader}
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values, errors, touched }) => (

          <Form>
            <Card className={classes.root}>
              <CardContent>
                {/* ----Invoice Condition--------------------------------------------- */}
                <div className='invoice-fontMedium'>
                  Set up expired invoice conditions (optional)
                </div>
                <Box className={classes.content}>
                  <Grid container>
                    <Grid container item xs={6} className="content-title-part">
                      <div>
                        <div className='invoice-fontMedium'>
                          Countdown before job start
                        </div>
                        <div className='fee-icon'>
                          <Tooltip
                            title={t('reschedule_message.compliance_details_tooltip')}
                            classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
                            placement='right-start'
                            arrow
                          >
                            <ErrorOutlineIcon style={{ fill: '#2d95f4' }} />
                          </Tooltip>
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="expand-part">
                      <IconButton
                        color="primary"
                        onClick={() => handleExpandClick('countdown_before_job_start')}
                      >
                        {expandedItem.countdown_before_job_start ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={expandedItem.countdown_before_job_start} timeout="auto" unmountOnExit>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className='invoice-fontSmall'>
                        Set expiration time
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="countdown_before_job_start_days">Days (1Day = 24 Hrs)</InputLabel>
                        <Field
                          as={TextField}
                          name="countdown_before_job_start.days"
                          id="countdown_before_job_start_days"
                          placeholder="Days"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.countdown_before_job_start?.days && Boolean(errors.countdown_before_job_start?.days)}
                          helperText={touched.countdown_before_job_start?.days && errors.countdown_before_job_start?.days}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="countdown_before_job_start_hrs">Hrs</InputLabel>
                        <Field
                          as={TextField}
                          name="countdown_before_job_start.hrs"
                          id="countdown_before_job_start_hrs"
                          placeholder="Hrs"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.countdown_before_job_start?.hrs && Boolean(errors.countdown_before_job_start?.hrs)}
                          helperText={touched.countdown_before_job_start?.hrs && errors.countdown_before_job_start?.hrs}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
                <Box className={classes.content}>
                  <Grid container>
                    <Grid container item xs={6} className="content-title-part">
                      <div>
                        <div className='invoice-fontMedium'>
                          Countdown after invoice created
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="expand-part">
                      <IconButton
                        color="primary"
                        onClick={() => handleExpandClick('countdown_after_invoice_created')}
                      >
                        {expandedItem.countdown_after_invoice_created ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={expandedItem.countdown_after_invoice_created} timeout="auto" unmountOnExit>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className='invoice-fontSmall'>
                        Set expiration time
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="countdown_after_invoice_created_days">Days (1Day = 24 Hrs)</InputLabel>
                        <Field
                          as={TextField}
                          name="countdown_after_invoice_created.days"
                          id="countdown_after_invoice_created_days"
                          placeholder="Days"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.countdown_after_invoice_created?.days && Boolean(errors.countdown_after_invoice_created?.days)}
                          helperText={touched.countdown_after_invoice_created?.days && errors.countdown_after_invoice_created?.days}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="countdown_after_invoice_created_hrs">Hrs</InputLabel>
                        <Field
                          as={TextField}
                          name="countdown_after_invoice_created.hrs"
                          id="countdown_after_invoice_created_hrs"
                          placeholder="Hrs"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.countdown_after_invoice_created?.hrs && Boolean(errors.countdown_after_invoice_created?.hrs)}
                          helperText={touched.countdown_after_invoice_created?.hrs && errors.countdown_after_invoice_created?.hrs}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>

                {/* ----Booking Condition--------------------------------------------- */}
                <div className='invoice-fontMedium' style={{ marginTop: 40 }}>
                  Set up advance booking conditions (optional)
                </div>
                <Box className={classes.content}>
                  <Grid container>
                    <Grid container item xs={6} className="content-title-part">
                      <div>
                        <div className='invoice-fontMedium'>
                          Minimum advance booking time
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="expand-part">
                      <IconButton
                        color="primary"
                        onClick={() => handleExpandClick('minimum_advance_booking_time')}
                      >
                        {expandedItem.minimum_advance_booking_time ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={expandedItem.minimum_advance_booking_time} timeout="auto" unmountOnExit>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className='invoice-fontSmall'>
                        Set expiration time
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="minimum_advance_booking_time_days">Days (1Day = 24 Hrs)</InputLabel>
                        <Field
                          as={TextField}
                          name="minimum_advance_booking_time.days"
                          id="minimum_advance_booking_time_days"
                          placeholder="Days"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.minimum_advance_booking_time?.days && Boolean(errors.minimum_advance_booking_time?.days)}
                          helperText={touched.minimum_advance_booking_time?.days && errors.minimum_advance_booking_time?.days}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="minimum_advance_booking_time_hrs">Hrs</InputLabel>
                        <Field
                          as={TextField}
                          name="minimum_advance_booking_time.hrs"
                          id="minimum_advance_booking_time_hrs"
                          placeholder="Hrs"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.minimum_advance_booking_time?.hrs && Boolean(errors.minimum_advance_booking_time?.hrs)}
                          helperText={touched.minimum_advance_booking_time?.hrs && errors.minimum_advance_booking_time?.hrs}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
                <Box className={classes.content}>
                  <Grid container>
                    <Grid container item xs={6} className="content-title-part">
                      <div>
                        <div className='invoice-fontMedium'>
                          Maximum advance booking time
                        </div>
                      </div>
                    </Grid>
                    <Grid item xs={6} className="expand-part">
                      <IconButton
                        color="primary"
                        onClick={() => handleExpandClick('maximum_advance_booking_time')}
                      >
                        {expandedItem.maximum_advance_booking_time ? <ExpandLessIcon className={classes.icon} /> : <ExpandMoreIcon className={classes.icon} />}
                      </IconButton>
                    </Grid>
                  </Grid>
                  <Collapse in={expandedItem.maximum_advance_booking_time} timeout="auto" unmountOnExit>
                    <Grid container spacing={1}>
                      <Grid item xs={12} className='invoice-fontSmall'>
                        Set expiration time
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="maximum_advance_booking_time_days">Days (1Day = 24 Hrs)</InputLabel>
                        <Field
                          as={TextField}
                          name="maximum_advance_booking_time.days"
                          id="maximum_advance_booking_time_days"
                          placeholder="Days"
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.maximum_advance_booking_time?.days && Boolean(errors.maximum_advance_booking_time?.days)}
                          helperText={touched.maximum_advance_booking_time?.days && errors.maximum_advance_booking_time?.days}
                        />
                      </Grid>
                    </Grid>
                  </Collapse>
                </Box>
              </CardContent>
              <CardActions >
                <Grid container item xs={12} justify='flex-end'>
                  <Button color='primary' variant='contained'
                  // onClick={() => setOpenModal(true)} 
                  >
                    {t('save')}
                  </Button>
                </Grid>
              </CardActions>
            </Card>
          </Form>
        )}
      </Formik>
    </div >
  )
}

export default InvoiceExpirationPane