import React, { useEffect, useState } from 'react'
import { Button, Card, CardActions, CardContent, CardHeader, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, InputLabel, makeStyles, Modal, TextField } from '@material-ui/core'
import { Field, Form, Formik } from 'formik'
import * as yup from 'yup';
import { callService } from 'utils/callService';
import useFullPageLoader from 'hooks/useFullPageLoader';
import { useTranslation } from 'react-i18next';
import './ServiceSettingPane.scss'

//Custom Style
const useStyles = makeStyles({
  root: {
    minWidth: 275,
    maxWidth: 900,
    margin: '10px',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  content: {
    padding: '20px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    marginTop: '10px',
  },
  icon: {
    marginLeft: '5px',
  },
  arrow: {
    color: '#2d95f4'
  },
  tooltip: {
    backgroundColor: '#2d95f4',
    fontSize: '14px'
  }
});

//Validation
const validationSchema = yup.object().shape({
  reschedule_acceptance_time: yup.object().shape({
    hrs: yup.number().integer('Hours must be an integer').min(0, 'Hours must be positive'),
    mins: yup.number().integer('Minutes must be an integer').min(0, 'Minutes must be positive')
  }),
});

//Main
function ServiceSettingPane() {
  const classes = useStyles()
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const { t } = useTranslation('settings')
  const [initialValues, setInitialValues] = useState({ reschedule_acceptance_time: { hrs: 0, mins: 0, id: null } })
  const [openModal, setOpenModal] = useState(false)

  useEffect(() => {
    fetchServiceSettings()
  }, [])

  const reverseCalculate = (data) => {
    const hrs = Math.floor(data.rescheduled_accept_before_minutes / 60)
    const min = data.rescheduled_accept_before_minutes % 60
    return { reschedule_acceptance_time: { hrs: hrs, mins: min, id: data.id } }
  }

  const fetchServiceSettings = async () => {
    try {
      showLoader()
      const response = await callService.get(`${process.env.REACT_APP_RESCHEDULE_API}/reschedule-config`)
      if (response.status === 200) {
        hideLoader()
        setInitialValues(reverseCalculate(response.data))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const submitData = async (data) => {
    try {
      showLoader()
      const response = await callService.patch(
        `${process.env.REACT_APP_RESCHEDULE_API}/reschedule-config/${data.reschedule_acceptance_time.id}`,
        {
          "reschedule_accept_hours": data.reschedule_acceptance_time.hrs,
          "reschedule_accept_minutes": data.reschedule_acceptance_time.mins
        })
      if (response.status === 200) {
        hideLoader()
        setOpenModal(false)
        return response
      }
    } catch (error) {
      console.log(error)
    }
  }

  const handleSubmit = (values) => {
    submitData(values)
  }

  return (
    <div id="service-setting-pane">
      {loader}
      <Formik
        enableReinitialize
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {
          ({ values, errors, touched }) => {

            return (
              <Form>
                <Card className={classes.root}>
                  <CardHeader title={t('service_settings')} style={{ backgroundColor: '#EEEEEE' }} />
                  <CardContent>
                    <Grid container >
                      <Grid item xs={12} className="content-title-part">
                        <div className='text-medium' style={{ marginBottom: 20 }}>
                          {t('reschedule_acceptance_time')}
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="reschedule_acceptance_time_hrs">{t('hours_24hrs')}</InputLabel>
                        <Field
                          as={TextField}
                          name="reschedule_acceptance_time.hrs"
                          id="reschedule_acceptance_time_hrs"
                          placeholder="Hours"
                          value={values.reschedule_acceptance_time?.hrs}
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.reschedule_acceptance_time?.hrs && Boolean(errors.reschedule_acceptance_time?.hrs)}
                          helperText={touched.reschedule_acceptance_time?.hrs && errors.reschedule_acceptance_time?.hrs}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <InputLabel htmlFor="reschedule_acceptance_time_mins">{t('min')}</InputLabel>
                        <Field
                          as={TextField}
                          name="reschedule_acceptance_time.mins"
                          id="reschedule_acceptance_time_mins"
                          placeholder="Min"
                          value={values.reschedule_acceptance_time?.mins}
                          variant="outlined"
                          autoComplete="off"
                          autoCorrect="off"
                          fullWidth
                          error={touched.reschedule_acceptance_time?.mins && Boolean(errors.reschedule_acceptance_time?.mins)}
                          helperText={touched.reschedule_acceptance_time?.mins && errors.reschedule_acceptance_time?.mins}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <div className='subtext-small'>
                          {t('timeframe_for_rescheduled_jobs_acceptance')}
                        </div>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions >
                    <Grid container item xs={12} justify='flex-end'>
                      <Button color='primary' variant='contained' onClick={() => setOpenModal(true)} >
                        {t('save')}
                      </Button>
                    </Grid>
                  </CardActions>
                </Card>
                <Dialog
                  maxWidth={'xs'}
                  open={openModal}
                  onClose={() => setOpenModal(false)}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title" color='primary' style={{ textAlign: 'center', color: '#1B4171' }}>
                    {t('acceptance_timeframe_rescheduled')}
                  </DialogTitle>
                  <DialogContent>
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                      {t('acceptance_timeframe_all_services')}
                    </DialogContentText>
                  </DialogContent>
                  <DialogActions className='dialog-action-spacing' style={{ padding: '16px 24px' }}>
                    <Button variant='outlined' color='primary' fullWidth onClick={() => setOpenModal(false)}>
                      {t('cancel')}
                    </Button>
                    <Button variant='contained' color='primary' type='submit' fullWidth onClick={() => handleSubmit(values)}>
                      {t('confirm')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </Form>
            )
          }}
      </Formik>
    </div>
  )
}

export default ServiceSettingPane
