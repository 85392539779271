/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState } from 'react'
import './reschedule.scss'
import { Field, ErrorMessage } from 'formik'

import './setupfee.scss'

const SetupBaseFee = (props) => {
  const {
    action,
    rawData,
    switchUpdate
  } = props
  const [open, setOpen] = useState(false)
  const [selectShow, setSelectShow] = useState('')
  const [valueCheck, setValueCheck] = useState('')

  const changeShow = (value, nameData) => {
    setSelectShow(value)
    setOpen(false)
    action.values.create_at && switchUpdate(action)
    action.setFieldValue(nameData, value)
  }

  const checkPlace = () => {
    if (selectShow === 'percent') {
      return 'Percentage  %'
    } else if (selectShow === 'fixed_price') {
      return 'Fixed Price  ฿'
    }
    return 'Select choice'
  }

  const changeValue = (valueNumber, valueName) => {
    const regex = /^[0-9]*(\.[0-9]{0,2})?$/
    const b = regex.test(valueNumber)
    if (b) {
      setValueCheck(valueNumber)
      action.setFieldValue(valueName, valueNumber)
    }
    action.values.create_at && switchUpdate(action)
    setOpen(false)
  }

  const renderIcon = () => {
    return (
      <>
        {selectShow === 'percent' || rawData.base_condition_fee_type === 'percent' ? '%' : ''}
        {selectShow === 'fixed_price' || rawData.base_condition_fee_type === 'fixed_price' ? '฿' : ''}
      </>
    )
  }


  return (
    <div>
      <div className='d-flex align-item-center section-set-up-fees-custom'>
        <div>
          <div className='section-select-choice'>
            <div
              className='input-select-custom pointer'
              onClick={() => {
                setOpen((pre) => !pre)
              }}
              onBlur={() => setOpen(false)}
            >
              <div>
                <Field
                  name={`base_condition_fee_value`}
                  className={`input-select-set-fee font-fee ${valueCheck.length > 0 && 'show-icon-percen-bath'
                    }`}
                  placeholder={checkPlace()}
                  onChange={(e) => {
                    changeValue(e.target.value, `base_condition_fee_value`)
                  }}
                  autoComplete='off'
                />
                <div className='icon'>
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='16.648'
                    height='9.52'
                    viewBox='0 0 16.648 9.52'
                    className={`${open ? 'animation-close' : 'animation-open'}`}
                  >
                    <path
                      id='Icon_ionic-ios-arrow-down'
                      data-name='Icon ionic-ios-arrow-down'
                      d='M14.515,17.9l6.3-6.3a1.185,1.185,0,0,1,1.68,0,1.2,1.2,0,0,1,0,1.685l-7.133,7.138a1.188,1.188,0,0,1-1.641.035L6.534,13.286A1.19,1.19,0,0,1,8.215,11.6Z'
                      transform='translate(-6.188 -11.246)'
                      fill='gray'
                    />
                  </svg>
                </div>

                <div className='show-select-custom'>{renderIcon()}</div>
              </div>
            </div>
            {open && (
              <div className='show-menu pointer'>
                <div
                  className='d-flex choice-custom-fee'
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    changeShow('percent', `base_condition_fee_type`)
                  }}
                >
                  <div>Percentage</div>
                  <div>%</div>
                </div>
                <div
                  className='d-flex choice-custom-fee'
                  onMouseDown={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    changeShow('fixed_price', `base_condition_fee_type`)
                  }}
                >
                  <div>Fixed Price</div>
                  <div>฿</div>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`base_condition_fee_value`}
            component='div'
            className='field-error'
          />
        </div>
        <div className='text-validate pl-2'>
          <ErrorMessage
            name={`base_condition_fee_type`}
            component='div'
            className='field-error'
          />
        </div>
      </div>
    </div>
  )
}

export default SetupBaseFee
