/* eslint-disable prefer-const */
/* eslint-disable camelcase */
import { Map } from 'immutable'
import { withFormik } from 'formik'
import * as Yup from 'yup'
import { compose } from 'redux'

import keyIn from 'utils/keyIn'
import addPositionToCollection from 'utils/addPositionToCollection'
import withFlashMessage from 'hoc/withFlashMessage'

import CategoryForm from './CategoryForm'

const formikConfig = {
  mapPropsToValues: ({ category = Map() }) => {
    const categortServices = category.get('category_services')
    let categortServicesAttributes

    if (categortServices) {
      categortServicesAttributes = categortServices
        .map((category_service) =>
          category_service.filter(keyIn('id', 'position', 'service_id'))
        )
        .toJS()
    }

    return {
      name_en: category.get('name_en') || '',
      name_th: category.get('name_th') || '',
      region_id: category.get('region_id') || '',
      category_services_attributes: categortServicesAttributes
    }
  },

  validationSchema: Yup.object().shape({
    name_en: Yup.string().required(),
    name_th: Yup.string()
  }),

  handleSubmit: (values, { props, setSubmitting }) => {
    let newVal = addPositionToCollection(values, 'category_services_attributes')
    const updatedCategory = newVal.category_services_attributes.map(
      ({ service_id, ...data }) => data
    )

    const setData = {
      category_services_attributes: updatedCategory,
      name_en: newVal.name_en,
      name_th: newVal.name_th
    }
    props
      .onSubmit(setData)
      .then((response) => {
        props.push(`/categories/${response.body.id}`)
      })
      .catch((error) => {
        setSubmitting(false)

        props.setFlashMessage('error', error.response.body.message)
      })
  }
}

export default compose(withFlashMessage, withFormik(formikConfig))(CategoryForm)
