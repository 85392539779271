/* eslint-disable react-hooks/rules-of-hooks */
import React, { useState, useContext, useEffect } from 'react'
import { Grid, Container, Typography } from '@material-ui/core'
import LocaleContext from 'contexts/LocaleContext'
import moment from 'moment'
import { useParams } from 'react-router-dom'


import './Fee.scss'
import { callService } from 'utils/callService'
import { useSelector } from 'react-redux'
import { compose } from 'redux'
import withLocalizedFields from 'hoc/withLocalizedFields'
import { useTranslation } from 'react-i18next'

const index = (props) => {
  const locale = useContext(LocaleContext)
  const [cancellationData, setCancellationData] = useState()
  const [rescheduleData, setRescheduleData] = useState()
  const params = useParams()
  const serviceID = params.id
  const redux = useSelector((state) => state)
  const tenant_id = redux.toJS().cancellation.tenantId
  const { t } = useTranslation('services')

  useEffect(() => {
    if (tenant_id) {
      fetchCancellation()
      fetchReschedule()
    }
  }, [tenant_id])

  function reverseCalculate(data) {
    const hrs = Math.floor(data.before_minutes / 3600000)
    const min = Math.floor((data.before_minutes - (hrs * 3600000)) / 60000)
    return { hrs: hrs, min: min, setFee: data.type, value: data.value }
  }

  const fetchCancellation = async () => {
    try {
      const response = await callService.get(`${process.env.REACT_APP_CANCELLATION_BASE_API}/api/cancellations?tenant_id=${tenant_id}&service_id=${serviceID}`)
      if (response.status === 200 && response.data.data.cancellations !== null) {
        let data = response.data.data.cancellations
        let completeData = {
          ...data,
          cancellationConditionFees: data.cancellationConditionFees.map(item => reverseCalculate(item)),
        }

        setCancellationData(completeData)
      }
    } catch (error) {
      alert(`An error occurred while fetch the cancellation: ${error.message}`)
    }
  }

  const fetchReschedule = async () => {
    try {
      const response = await callService.get(`${process.env.REACT_APP_RESCHEDULE_API}/services/${serviceID}/reschedule`)
      if (response.status === 200) {
        let data = response.data
        const completeData = {
          ...data,
          rescheduleConditionFees: data.reschedule_condition_fees.map(item => reverseCalculate(item))
        }
        setRescheduleData(completeData)
      }
    } catch (error) {
      alert(`An error occurred while fetch the reschedule: ${error.message}`)
    }
  }

  const renderDisplay = (values) => {
    switch (values) {
      case 'checkbox':
        return 'Checkbox'
      case 'radio':
        return 'Radio'
      case 'textarea':
        return 'Textarea'
      case 'dropdown':
        return 'Dropdown'
      default:
        return values
    }
  }

  const rescheduleAvailable = rescheduleData && rescheduleData?.is_published
  const cancellationAvailable = cancellationData && cancellationData?.is_published

  return (
    <div>
      {
        //reschedule
        rescheduleAvailable && (
          <div className='section-have-data'>
            <h2 className='pl-3'>{t('reschedule_summary')}</h2>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <div className='border-default-fee-condetion'>
                    <div>
                      <div className='title-day'>
                        {t('published_at')} {moment(rescheduleData.published_at).format('DD MMM YYYY | HH:mm')}
                      </div>
                    </div>
                    <div className='text-topic pt-16'>
                      <div dangerouslySetInnerHTML={{ __html: rescheduleData[`compliance_details_${locale}`] }} />
                    </div>
                    <hr />
                    <div className='pt-16'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='text-topic'>{t('reason_for_reschedule')}</div>
                        <div className='text-topic'>{t('display')}</div>
                      </div>
                      <div>
                        <ol className='custom-list-reason-cancel'>
                          {rescheduleData.reschedule_reason_choices.map((item, k) => {
                            return (
                              <li key={k}>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='section-reasons-topic'>
                                    {item.reschedule_reasons[`name_${locale}`]}
                                  </div>
                                  <div>
                                    <div className='box-display-cancel'>
                                      {renderDisplay('checkbox')}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className='border-default-fee-condetion'>
                    <div className='topic-fee-and-condition'>{t('setup_fees')}</div>
                    <div className='title-fee-and-condition pt-16'>
                      {t('condition_of_charge')}
                    </div>
                    <div>{t('condition_message')}</div>
                    <div className='pt-16'>
                      {
                        rescheduleData.rescheduleConditionFees.map((data, k) => {
                          return (
                            <div
                              key={k}
                              className='d-flex align-items-center justify-content-between'
                            >
                              <div>
                                <span className='number-detail'>{data.hrs}</span>
                                <span className='text-detail'>Hrs</span>
                                <span className='number-detail'>{data.min}</span>
                                <span className='text-detail'>Min</span>
                              </div>
                              <div>
                                <span className='price-detail'>{data.value}</span>
                                <span className='text-detail'>
                                  {data.setFee === 'percent' ? '%' : '฿'}
                                </span>
                              </div>
                              <div />
                            </div>
                          )
                        })}
                    </div>
                    <div className='py-32'>
                      {rescheduleData && (
                        <div>
                          <>{t('miscellaneous_fee')}</>
                          <div className='price-detail'>
                            {`${rescheduleData.base_condition_fee_value} ${rescheduleData.base_condition_fee_type === 'percent' ? '%' : '฿'}`} {t('of_service_price')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      }

      {
        //cancellation
        cancellationAvailable && (
          <div className='section-have-data'>
            <h2 className='pl-3'>{t('cancellation_summary')}</h2>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={7}>
                  <div className='border-default-fee-condetion'>
                    <div>
                      <div className='title-day'>
                        {t('published_at')} {moment(cancellationData.published_at).format('DD MMM YYYY | HH:mm')}
                      </div>
                    </div>
                    <div className='text-topic pt-16'>
                      <div dangerouslySetInnerHTML={{ __html: cancellationData[`compliance_details_${locale}`] }} />
                    </div>
                    <hr />
                    <div className='pt-16'>
                      <div className='d-flex justify-content-between align-items-center'>
                        <div className='text-topic'>{t('reason_for_cancellation')}</div>
                        <div className='text-topic'>{t('display')}</div>
                      </div>
                      <div>
                        <ol className='custom-list-reason-cancel'>
                          {cancellationData.cancellationReasons.map((item, k) => {
                            return (
                              <li key={k}>
                                <div className='d-flex justify-content-between align-items-center'>
                                  <div className='section-reasons-topic'>
                                    {item[`reason_${locale}`]}
                                  </div>
                                  <div>
                                    <div className='box-display-cancel'>
                                      {renderDisplay('checkbox')}
                                    </div>
                                  </div>
                                </div>
                              </li>
                            )
                          })}
                        </ol>
                      </div>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={12} md={5}>
                  <div className='border-default-fee-condetion'>
                    <div className='topic-fee-and-condition'>{t('setup_fees')}</div>
                    <div className='title-fee-and-condition pt-16'>
                      {t('condition_of_charge')}
                    </div>
                    <div>{t('condition_message')}</div>
                    <div className='pt-16'>
                      {
                        cancellationData.cancellationConditionFees.map((data, k) => {
                          return (
                            <div
                              key={k}
                              className='d-flex align-items-center justify-content-between'
                            >
                              <div>
                                <span className='number-detail'>{data.hrs}</span>
                                <span className='text-detail'>Hrs</span>
                                <span className='number-detail'>{data.min}</span>
                                <span className='text-detail'>Min</span>
                              </div>
                              <div>
                                <span className='price-detail'>{data.value}</span>
                                <span className='text-detail'>
                                  {data.setFee === 'percent' ? '%' : '฿'}
                                </span>
                              </div>
                              <div />
                            </div>
                          )
                        })}
                    </div>
                    <div className='py-32'>
                      {cancellationData && (
                        <div>
                          <>{t('miscellaneous_fee')}</>
                          <div className='price-detail'>
                            {`${cancellationData.base_condition_fee_value} ${cancellationData.base_condition_fee_type === 'percent' ? '%' : '฿'}`} {t('of_service_price')}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        )
      }



      {
        //Empty Data
        !rescheduleAvailable && !cancellationAvailable &&
        <div className='empty-fee-and-condition-container'>
          <img src={`${process.env.PUBLIC_URL}/empty-paper.svg`} alt="My SVG" />
          <Typography className='empty-main-message'>
            {t('empty-main-message')}
          </Typography>
          <Typography className='empty-sub-message'>
            {t('sub-main-message')}
          </Typography>
        </div>
      }
    </div>
  )
}

export default compose(withLocalizedFields)(index) 
