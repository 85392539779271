import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Grid } from '@material-ui/core'
import { useTranslation, withTranslation } from 'react-i18next'

import Panel from 'components/containers/Panel'
import { AttributesTable, Attribute, LocalizedAttribute } from 'components/tables/NewAttributesTable'
import DateTimeFormatter from 'components/formatters/DateTimeFormatter'
import LocaleContext from 'contexts/LocaleContext'

export function ProviderStrengthOverviewPane({ providerStrength, t }) {
  const { i18n } = useTranslation();

  return (
    <Grid container>
      <Grid item xs={12}>
        <Panel title={t('details')}>
          <AttributesTable resource={providerStrength} basic='very'>
            <Attribute name='id' />
            <LocalizedAttribute name='name' lng={i18n.language} />
            <Attribute name='icon_url' render={iconUrl => <img src={iconUrl} alt='Provider Str Icon' />} />
            <Attribute name='updated_at' component={DateTimeFormatter} />
            <Attribute name='created_at' component={DateTimeFormatter} />
          </AttributesTable>
        </Panel>
      </Grid>
    </Grid>
  )
}

ProviderStrengthOverviewPane.propTypes = {
  providerStrength: ImmutablePropTypes.map,

  t: PropTypes.func
}

ProviderStrengthOverviewPane.defaultProps = {
  providerStrength: Map()
}

export default withTranslation('provider_strengths')(ProviderStrengthOverviewPane)
