/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import dayjs from 'dayjs'

import { Grid } from '@material-ui/core'

import './normalbtn.scss'

const index = ({ action, time, setTime }) => {
  const selectDate = (id, time) => {
    setTime((prevTime) => {
      const updateNormal = prevTime.normal.map((normal) => {
        if (normal.id === id) {
          return {
            ...normal,
            active: true
          }
        } else {
          return {
            ...normal,
            active: false
          }
        }
      })

      return {
        ...prevTime,
        normal: updateNormal
      }
    })
    action.setFieldValue('start_time_select', time)
  }

  return (
    <div>
      <Grid container spacing={3}>
        {time.normal.map((data, k) => {
          return (
            <Grid item xs={12} md={6} lg={4} key={k}>
              <div
                className={`btn-time-normal-job ${
                  data.active && 'btn-active-normal-job'
                } ${data.disableBtn && 'disable-btn-normal-job'}`}
                onMouseDown={(e) =>
                  data.disableBtn ? e.stopPropagation() : selectDate(data.id, data.time)
                }
              >
                {dayjs(data.time).format('HH:mm')}
              </div>
            </Grid>
          )
        })}
      </Grid>
    </div>
  )
}

export default index
