import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { withTranslation } from 'react-i18next'
import useData from 'hooks/useData'
import { EditRounded, Delete } from '@material-ui/icons'

import PageLayout from 'components/layouts/PageLayout'
import Tag from 'components/Tag'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { InvoiceCommentsPane, InvoiceOverviewPane } from './panes'
import InvoiceTimelinePage from './panes/InvoiceTimelinePane/InvoiceTimelinePane'

export function InvoiceShowPage({
  invoice,
  location,
  match,
  fetchComments,
  fetchInvoice,
  expectInvoice,
  fetchWallet,
  destroy,
  t
}) {
  const { loading } = useData(() =>
    fetchInvoice(match.params.id).then((result) => fetchWallet(result.body.customer.id))
  )
  useData(() => fetchComments(match.params.id))
  function deletingResource(e) {
    if (window.confirm('Are you sure you want to delete this?')) {
      destroy(match.params.id)
    }
  }

  function handleExpect() {
    expectInvoice(match.params.id)
  }

  function actions() {
    const buttons = [
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/invoices/${match.params.id}/edit`
      },
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: deletingResource
      }
    ]

    if (invoice.get('state') === 'pending') {
      return [
        {
          content: t('expect'),
          onClick: handleExpect
        },
        ...buttons
      ]
    } else {
      return buttons
    }
  }

  function subNavbar() {
    const path = `/invoices/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('navbar.overview')} to={path} />
        <NavigationMenuItem
          label={t('navbar.comments')}
          badge={invoice.get('comments', 0).size}
          to={`${path}/comments`}
        />
        <NavigationMenuItem label={'Timeline'} to={`${path}/timeline`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs() {
    return [
      {
        text: 'Invoices',
        link: '/invoices'
      },
      {
        text: invoice.get('number')
      }
    ]
  }

  return (
    <PageLayout
      title={invoice.get('number')}
      subtitle={<Tag value={invoice.get('state')} className='invoice-states' />}
      actions={actions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute path='/invoices/:id' component={InvoiceOverviewPane} exact />
        <AuthenticatedRoute
          path='/invoices/:id/comments'
          component={InvoiceCommentsPane}
        />
        <AuthenticatedRoute
          path='/invoices/:id/timeline'
          component={InvoiceTimelinePage}
        />
      </Switch>
    </PageLayout>
  )
}

InvoiceShowPage.propTypes = {
  invoice: ImmutablePropTypes.map,
  location: PropTypes.object,
  match: PropTypes.object,
  fetchComments: PropTypes.func,
  fetchInvoice: PropTypes.func,
  fetchWallet: PropTypes.func,
  expectInvoice: PropTypes.func,
  destroy: PropTypes.func,
  t: PropTypes.func
}

InvoiceShowPage.defaultProps = {
  invoice: Map(),

  fetchInvoice: () => { }
}

export default withTranslation(['actions', 'common'])(InvoiceShowPage)
