import React from 'react';
import {
  CircularProgress,
  Backdrop
} from '@material-ui/core';

const useFullPageLoader = () => {
  const [loading, setLoading] = React.useState(false);

  return [
    loading ? (
      <Backdrop open={loading} style={{
        zIndex: 1000,
        color: '#fff',
        backgroundColor: 'lightgrey',
        height: '100vh',
        opacity: 0.8,
      }}>
        <CircularProgress />
      </Backdrop>
    ) : null,
    () => setLoading(true),
    () => setLoading(false)
  ]
}

export default useFullPageLoader;
