/* eslint-disable prefer-const */
/* eslint-disable quotes */
import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { Switch } from 'react-router-dom'
import { EditRounded, Delete } from '@material-ui/icons'
import { withTranslation } from 'react-i18next'

import useData from 'hooks/useData'

import PageLayout from 'components/layouts/PageLayout'
import {
  NavigationMenuBar,
  NavigationMenuItem
} from 'components/layouts/NavigationMenuBar'
import AuthenticatedRoute from 'components/AuthenticatedRoute'

import { CategoryOverviewPane, CategoryServicesPane } from './panes'

export function CategoryShowPage({
  category,
  location,
  fetchCategory,
  t,
  push,
  destroy,
  setFlashMessage,
  match
}) {
  const { loading } = useData(() => fetchCategory(match.params.id))

  function handleDestroy() {
    if (window.confirm(t('delete_confirmation_prompt'))) {
      destroy(match.params.id)
        .then((response) => {
          push(`/categories`)
        })
        .catch((error) => {
          setFlashMessage('error', error.response.body.message)
        })
    }
  }

  function secondaryActions() {
    let actions = [
      {
        avatar: <EditRounded />,
        content: t('edit'),
        to: `/categories/${match.params.id}/edit`
      },
      {
        avatar: <Delete />,
        content: t('delete'),
        onClick: handleDestroy
      }
    ]

    return actions
  }

  function subNavbar() {
    const path = `/categories/${match.params.id}`

    return (
      <NavigationMenuBar path={location.pathname}>
        <NavigationMenuItem label={t('common:navbar.overview')} to={path} />
        <NavigationMenuItem label={t('services')} to={`${path}/services`} />
      </NavigationMenuBar>
    )
  }

  function breadcrumbs() {
    return [
      {
        text: t('title'),
        link: '/categories'
      },
      {
        text: category.get('name')
      }
    ]
  }

  return (
    <PageLayout
      title={category.get('name')}
      actions={secondaryActions()}
      subNavbar={subNavbar()}
      breadcrumbs={breadcrumbs()}
      isLoading={loading}
    >
      <Switch>
        <AuthenticatedRoute
          path='/categories/:id'
          component={CategoryOverviewPane}
          exact
        />
        <AuthenticatedRoute
          path='/categories/:id/services'
          component={CategoryServicesPane}
        />
      </Switch>
    </PageLayout>
  )
}

CategoryShowPage.propTypes = {
  category: ImmutablePropTypes.map,
  location: PropTypes.object,
  fetchCategory: PropTypes.func,
  t: PropTypes.func,
  push: PropTypes.func,
  destroy: PropTypes.func,
  setFlashMessage: PropTypes.func,
  match: PropTypes.object
}

CategoryShowPage.defaultProps = {
  category: Map()
}

export default withTranslation('categories')(CategoryShowPage)
