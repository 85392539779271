/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { Grid } from '@material-ui/core'
import { Field, ErrorMessage } from 'formik'

import Normal from './Normal'
import Slot from './Slot'

import './sectionRightJob.scss'

const index = ({ isSlot, action, timeSelect, setTimeSelect, setTime, time }) => {
  const changeValueHour = (value) => {
    action.setFieldValue('hours', value)
    action.setFieldValue('hhmm', `${value}:${action.values.min}`)
  }

  const changeValueMin = (value) => {
    action.setFieldValue('min', value)
    action.setFieldValue('hhmm', `${action.values.hours}:${value}`)
  }

  return (
    <div className='py-2'>
      {!isSlot && (
        <div
          className={`btn-switch-custom-time-job ${timeSelect && 'active-btn-switch'}`}
          onClick={() => setTimeSelect((pre) => !pre)}
        >
          <div className='text-inside-btn-switch'>
            {timeSelect ? 'Switch to time Selection' : 'Switch to custom time select'}
          </div>
        </div>
      )}

      <div className='py-2'>
        {timeSelect ? (
          <div>
            <div>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <div className='pb-1'>Hourse (24 hrs)</div>
                  <Field
                    type='text'
                    name='hours'
                    className='input-reschedule-job'
                    autoComplete='off'
                    value={action.values.hours}
                    onChange={(e) => changeValueHour(e.target.value)}
                  />
                  <div className='text-validate mt-1'>
                    <ErrorMessage name='hours' component='div' className='field-error' />
                  </div>
                  <div className='text-validate mt-1'>
                    <ErrorMessage name='hhmm' component='div' className='field-error' />
                  </div>
                </Grid>
                <Grid item xs={12} md={6}>
                  <div className='pb-1'>Minutes</div>
                  <Field
                    type='text'
                    name='min'
                    className='input-reschedule-job'
                    autoComplete='off'
                    value={action.values.min}
                    onChange={(e) => changeValueMin(e.target.value)}
                  />
                  <div className='text-validate mt-1'>
                    <ErrorMessage name='min' component='div' className='field-error' />
                  </div>
                </Grid>
              </Grid>
            </div>
          </div>
        ) : (
          <div>
            {isSlot ? (
              <Slot action={action} setTime={setTime} time={time} />
            ) : (
              <Normal action={action} time={time} setTime={setTime} />
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default index
