/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { withTranslation } from 'react-i18next'
import { Form } from 'formik'
import { Grid, Button, Divider, TextareaAutosize, Container, Typography } from '@material-ui/core'

import LocaleContext from 'contexts/LocaleContext'
import { Tabs, TabPane } from 'components/Tabs'
import { Field, LocalizedField, FastField } from 'components/fields/Field'

import Cancellation from 'components/Cancellation'
import Reschedule from 'components/Reschedule'
import SortableFieldArray from 'components/fields/SortableFieldArray'
import DropzoneInput from 'components/inputs/DropzoneInput'
import ResourceSelect from 'components/inputs/ResourceSelect'
import SwitchInput from 'components/inputs/SwitchInput'
import { categorySchema, regionSchema, paymentMethodSchema } from '@seekster/schemas'
import ServiceLineItemFormSection from 'modules/services/ServiceLineItemFormSection'
import ServiceDetailFormSection from 'modules/services/ServiceDetailFormSection'
import ServiceScopeFormSection from 'modules/services/ServiceScopeFormSection'
import ServiceQuestionFormSection from 'modules/services/ServiceQuestionFormSection'
import LogisticsPricingFormSection from 'modules/services/LogisticsPricingFormSection'
import TextCreatableSelect from 'components/inputs/TextCreatableSelect'
import { DisabledDates, SlotTimes } from './sections'
import {
  // useDispatch,
  useSelector
} from 'react-redux'

// import moment from 'moment'

import './cancelService.scss'
import moment from 'moment'
import { AppLayoutContext } from 'components/layouts/AppLayout'

export const ServiceFormContext = React.createContext()

export function ServiceForm({ service, isSubmitting, errors, t, ...rest }) {
  const locale = useContext(LocaleContext)
  const [mockData, setMockData] = useState('Cancel')
  const [saveButton, setSaveButton] = useState(false)
  const [cancelOpen, setUpCancelOpen] = useState(true)
  const [TimeSave] = useState(false)
  const generalConfig = useContext(AppLayoutContext).generalConfig
  // const dispatch = useDispatch()
  const redux = useSelector((state) => state)
  const tanantId = redux.toJS().cancellation.tenantId
  const [update, setUpdate] = useState(false)
  const [conditionFeePublishDate, setConditionFeePublishDate] = useState()
  const [enableCancellation, setEnableCancellation] = useState(false)
  const [enableReschedule, setEnableReschedule] = useState(false)

  useEffect(() => {
    if (generalConfig?.is_cancellation_enabled === true) {
      setMockData('Cancel')
    } else if (generalConfig?.is_cancellation_enabled === false) (
      setMockData('Reschedule')
    )
  }, [generalConfig])

  return (
    <Form
      style={{ marginBottom: '100px' }}
      className={`main-section-form ${!cancelOpen && 'custom-margin'}`}
    >
      <ServiceFormContext.Provider value={{ setConditionFeePublishDate, setEnableCancellation, enableCancellation, setEnableReschedule, enableReschedule }}>
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12} sm={9}>
            <Tabs contained scrollButtons='off'>
              <TabPane label='Service Details'>
                <div className='custom-service-padding'>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                      <LocalizedField name='name' />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field name='code' required />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='category_ids'
                        component={ResourceSelect}
                        schema={categorySchema}
                        isMulti
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='region_id'
                        component={ResourceSelect}
                        schema={regionSchema}
                        required
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field name='lead_time' />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='payment_method_ids'
                        component={ResourceSelect}
                        schema={paymentMethodSchema}
                        isMulti
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FastField name='requestable' component={SwitchInput} noLabel />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FastField name='registerable' component={SwitchInput} noLabel />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FastField name='public' component={SwitchInput} noLabel />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FastField name='is_popular' component={SwitchInput} noLabel />
                    </Grid>

                    <Grid item xs={12}>
                      <SortableFieldArray
                        name='details_attributes'
                        component={ServiceDetailFormSection}
                        service={service}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SortableFieldArray
                        name='scopes_attributes'
                        component={ServiceScopeFormSection}
                        service={service}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <SortableFieldArray
                        name='logistics_pricings_attributes'
                        component={LogisticsPricingFormSection}
                        service={service}
                      />
                    </Grid>
                  </Grid>
                </div>
              </TabPane>

              <TabPane label='SLOT TIMES'>
                <div className='custom-service-padding'>
                  <SortableFieldArray name='slots_attributes' component={SlotTimes} />
                </div>
              </TabPane>

              <TabPane label='Disabled Dates'>
                <div className='custom-service-padding'>
                  <DisabledDates {...rest} />
                </div>
              </TabPane>

              <TabPane label='Images'>
                <div className='custom-service-padding'>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <LocalizedField
                        name='banner'
                        component={DropzoneInput}
                        width={1140}
                        height={300}
                        initialValue={service.get(`banner_${locale}_url`)}
                        hint={`${t('image_dimension_hint')} 1140 x 300 pixels`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='icon'
                        component={DropzoneInput}
                        width={256}
                        height={256}
                        initialValue={service.get('icon_url')}
                        hint={`${t('image_dimension_hint')} 256 x 256 pixels`}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <Field
                        name='thumbnail'
                        component={DropzoneInput}
                        width={256}
                        height={256}
                        initialValue={service.get('thumbnail_url')}
                        hint={`${t('image_dimension_hint')} 256 x 256 pixels`}
                      />
                    </Grid>
                  </Grid>
                </div>
              </TabPane>

              <TabPane label={t('service_lines')}>
                <div className='custom-service-padding'>
                  <SortableFieldArray
                    name='packages_attributes'
                    component={ServiceLineItemFormSection}
                    hasChildren
                  />
                </div>
              </TabPane>

              <TabPane label='Questions'>
                <div className='custom-service-padding'>
                  <Grid container spacing={0.5}>
                    <Grid item xs={12}>
                      <SortableFieldArray
                        name='request_questions_attributes'
                        component={ServiceQuestionFormSection}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Divider />
                    </Grid>

                    <Grid item xs={12}>
                      <SortableFieldArray
                        name='report_questions_attributes'
                        component={ServiceQuestionFormSection}
                      />
                    </Grid>
                  </Grid>
                </div>
              </TabPane>

              <TabPane label='SEO'>
                <div className='custom-service-padding'>
                  <LocalizedField name='title' />
                  <LocalizedField name='description' component={TextareaAutosize} />
                  <Field name='keywords' component={TextCreatableSelect} />
                </div>
              </TabPane>

              <TabPane label='Legal'>
                <div className='custom-service-padding'>
                  <LocalizedField name='terms' component={TextareaAutosize} />
                </div>
              </TabPane>

              <TabPane label='Fee & Conditions' onClick={() => setSaveButton(true)}>
                <div className='custom-main-form'>
                  {
                    mockData === "Cancel" &&
                    generalConfig?.is_cancellation_enabled &&
                    <div className='section-button-cancel'>
                      <div>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            checked={enableCancellation}
                            onChange={() => setEnableCancellation(prev => !prev)}
                          />
                          <span className='slider round' />
                        </label>
                      </div>
                      <div className='text-switch'>{t(`cancellation.${enableCancellation}`)} </div>
                    </div>
                  }

                  {
                    mockData === "Reschedule" &&
                    <div className='section-button-cancel'>
                      <div>
                        <label className='switch'>
                          <input
                            type='checkbox'
                            checked={enableReschedule}
                            onChange={() => setEnableReschedule(prev => !prev)}
                          />
                          <span className='slider round' />
                        </label>
                      </div>
                      <div className='text-switch'>{t(`reschedule.${enableReschedule}`)} </div>
                    </div>
                  }

                  <div className='box-section-time-and-update'>
                    {conditionFeePublishDate && (
                      <div className='show-time-sction-success'>
                        {t('published_at')} {moment(conditionFeePublishDate).format('DD MMM YYYY | HH:mm')}
                      </div>
                    )}
                    {/* {update && <div className='show-update-sction'>Save for Update</div>} */}
                  </div>
                  <div className='section-status-custom'>
                    {
                      generalConfig?.is_cancellation_enabled &&
                      <div
                        className={`custom-main pointer ${mockData === 'Cancel' && 'select-active'
                          }`}
                        onClick={() => setMockData('Cancel')}
                      >
                        {t('cancellation_conditions')}
                      </div>
                    }
                    <div
                      className={`custom-main pointer reschedule ${mockData === 'Reschedule' && 'select-active'
                        }`}
                      onClick={() => setMockData('Reschedule')}
                    >
                      {t('reschedule_conditions')}
                    </div>
                  </div>
                </div>
                <div className='section-show-status'>
                  <div className={`${!cancelOpen && 'active-disable'}`} />
                  <div className='pt-5 custom-scroll-cancellations'>
                    <Container>
                      {mockData === 'Cancel' && (
                        <div className='custom-label'>
                          <Cancellation update={setUpdate} tenant={tanantId} service={service} />
                        </div>
                      )}
                      {mockData === 'Reschedule' &&
                        <div className='custom-label'>
                          <Reschedule update={setUpdate} tenant={tanantId} service={service} />
                        </div>
                      }
                    </Container>
                  </div>
                </div>
              </TabPane>
            </Tabs>

            {!saveButton && (
              <Button
                variant='contained'
                color='primary'
                type='submit'
                size='large'
                disabled={isSubmitting}
                style={{ width: 157, marginTop: 50, float: 'right' }}
              >
                Save
              </Button>
            )}
          </Grid>
        </Grid>
      </ServiceFormContext.Provider>
    </Form>
  )
}

ServiceForm.propTypes = {
  service: ImmutablePropTypes.map,
  isSubmitting: PropTypes.bool,
  errors: PropTypes.object,
  t: PropTypes.func
}

ServiceForm.defaultProps = {
  service: Map()
}

export default withTranslation('services')(ServiceForm)
